import React from 'react';
import classNames from 'classnames';
import { string, func, object } from 'prop-types';
import loadable from '@loadable/component';
import css from './SectionHero.module.css';
import '../../styles/autocomplete-theme-classic.module.css';

const AlgoliaLazyLoadingComponent = loadable(() => import(/* webpackChunkName: "AlgoliaSearchComponent" */ "./AlgoliaComponent"));
const SectionStepsComponent = loadable(() => import(/* webpackChunkName: "SectionSteps" */ './SectionSteps'));
const SectionCategoriesComponent = loadable(() => import(/* webpackChunkName: "SectionCategories" */ './SectionCategories'));
const SectionSubCategoriesComponent = loadable(() => import(/* webpackChunkName: "SectionSubcategories" */ './SectionSubCategories'));

const SectionHero = props => {
  const {
    rootClassName,
    className,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroSection}>
          <AlgoliaLazyLoadingComponent {...props} />
          <SectionStepsComponent {...props} />
          <SectionCategoriesComponent {...props} />
          <SectionSubCategoriesComponent {...props} />
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null, initialSearchFormValues: {} };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
};

export default SectionHero;
