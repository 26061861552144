import React, { useState } from 'react';
import classNames from 'classnames';

import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { PaymentElement, useDots, useElements } from '@dots.dev/react-dots-js';

import config from '../../config';
import * as validators from '../../util/validators';
import { pathByRouteName } from '../../util/routes';
import getCountryCodes from '../../translations/countryCodes';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';

import {
  Form,
  FieldSelect,
  FieldTextInput,
  PrimaryButton,
  FieldCheckbox,
} from '../../components';

import css from './CheckoutForm.module.css';

const CheckoutFormComponent = props => {
  const {
    history,
    currentUser,
    currentUserListing,
    checkoutSubmit,
    onCreatePaymentCustomer,
    onAttachPaymentMethod,
    onDetachPaymentMethod,
    onGetPaymentMethod,
  } = props;
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const dots = useDots();
  const elements = useElements();
  const routes = routeConfiguration();

  const { email, profile } = (currentUser && currentUser.id && currentUser.attributes) || {};
  const { firstName, lastName, displayName, protectedData } = profile || {};
  const { phoneNumber, payment_method_id, customer_id, dotUser, dotUserId } = protectedData || {};
  const { businessName } = (currentUserListing && currentUserListing.id && currentUserListing.attributes.publicData) || {};

  const handleSubmit = async (values) => {
    if (!dots || !elements) return;
    setProcessing(true);
    const { saveAfterOnetimePayment } = values;
    // // Step 3: Use clientSecret from PaymentIntent and the CardElement
    // // to confirm payment with dots.confirmCardPayment()
    try {
      dots.addPaymentMethod({
        payment_method: {
          element: elements?.getElement('payment'),
          billing_details: {
            name: values.name, // not required
            address: {
              country: values.country,
              zip: values.zip,
            },
          },
        },
      }).then(paymentMethod => {
        if (!saveAfterOnetimePayment && typeof checkoutSubmit == 'function') {
          setProcessing(false);
          return checkoutSubmit({ payment_method_id: paymentMethod.id, });
        } else if (payment_method_id && customer_id) {
          onDetachPaymentMethod({
            customer_id,
            payment_method_id,
          })
            .then(() => {
              onAttachPaymentMethod({
                payment_method_id: paymentMethod.id,
                customer_id
              })
                .then(() => {
                  setProcessing(false);
                  return typeof checkoutSubmit == 'function'
                    ? checkoutSubmit({ payment_method_id: paymentMethod.id, })
                    : onGetPaymentMethod({
                      payment_method_id: paymentMethod.id,
                      customer_id: paymentCustomer.id
                    }).then(() => {
                      const params = (typeof window != 'undefined' && JSON.parse(window.sessionStorage.getItem('PaymentMethodsPage'))) || {};
                      const InvoicePage = pathByRouteName('InvoicePage', routes, params);
                      if (params && Object.keys(params)) {
                        window.sessionStorage.removeItem('PaymentMethodsPage');
                      }
                      return history.push(InvoicePage);
                    });
                });
            });
        } else {
          const payload = {
            country_code: values.country,
            email,
            first_name: firstName,
            last_name: lastName,
            metadata: {
              displayName
            },
          };

          // if (dotUserId || (dotUser && dotUser.id)) {
          //   Object.assign(payload, { user_id: (dotUserId || (dotUser && dotUser.id)), });
          // }

          // if (businessName) {
          //   Object.assign(payload, { business_name: businessName, });
          // }

          if (phoneNumber) {
            Object.assign(payload, { phone_number: phoneNumber, });
          }

          return onCreatePaymentCustomer(payload)
            .then(paymentCustomer => {
              if (paymentCustomer && paymentCustomer.id) {
                return onAttachPaymentMethod({
                  payment_method_id: paymentMethod.id,
                  customer_id: paymentCustomer.id
                })
                  .then(() => {
                    console.log('onAttachPaymentMethod');
                    setProcessing(false);
                    return typeof checkoutSubmit == 'function'
                      ? checkoutSubmit({ payment_method_id: paymentMethod.id, })
                      : onGetPaymentMethod({
                        payment_method_id: paymentMethod.id,
                        customer_id: paymentCustomer.id
                      }).then(() => {
                        const params = (typeof window != 'undefined' && JSON.parse(window.sessionStorage.getItem('PaymentMethodsPage'))) || {};
                        const InvoicePage = pathByRouteName('InvoicePage', routes, params);
                        if (params && Object.keys(params)) {
                          window.sessionStorage.removeItem('PaymentMethodsPage');
                        }
                        return history.push(InvoicePage);
                      });
                  });
              } else {
                throw Error('Payment Customer is not responding!');
              }
            });
        }
      }); //create a card charge of 96 dollar
    } catch (error) {
      console.error(error, '**** **** => error');
      setError(error.toString());
      setProcessing(false);
    }
  }

  return (
    <FinalForm
      {...props}
      onSubmit={handleSubmit}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          intl,
          form,
          formId,
          invalid,
          inProgress,
          pageData,
          values,
          handleSubmit,
        } = formRenderProps;

        const { bookingType = null } = pageData?.bookingData || {};

        const fieldOptions = {
          styles: {
            base: {
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: 'antialiased',
              color: '#32325d',
              fontWeight: '400',
              fontSize: '16px',
              colorPrimaryText: '#32325d',
              colorIcon: '#32325d',
            },
            invalid: {
              ':hover': {
                textDecoration: 'underline dotted red',
              },
              color: '#fa755a',
            },
            valid: {
              color: '#32CD32',
            },
          },
        };

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'CheckoutForm.nameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'CheckoutForm.namePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'CheckoutForm.nameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        //country
        const countryLabel = intl.formatMessage({
          id: 'CheckoutForm.counrtyNameLabel',
        });
        const countryPlaceholder = intl.formatMessage({
          id: 'CheckoutForm.countryPlaceholder',
        });
        const countryNameRequiredMessage = intl.formatMessage({
          id: 'CheckoutForm.countryNameRequired',
        });
        const countryRequired = validators.required(countryNameRequiredMessage);
        const countryCodes = getCountryCodes(config.locale, ['CA', 'US']);

        // Postal code
        const postalCodeLabel = intl.formatMessage({
          id: 'CheckoutForm.postalCodeLabel'
        });
        const postalCodePlaceholder = intl.formatMessage({
          id: 'CheckoutForm.postalCodePlaceholder',
        });
        const postalCodeRequired = validators.required(
          intl.formatMessage({
            id: 'CheckoutForm.postalCodeRequired',
          })
        );

        const labelText = intl.formatMessage({ id: 'StripePaymentForm.saveAfterOnetimePayment' });

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress || processing;
        const submitDisabled = invalid || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {bookingType !== "PACKAGE" ? <div>
              <div className={css.paymentElement}>
                <PaymentElement options={fieldOptions} />
              </div>

              {formId && formId == 'PaymentMethodsForm'
                ? <div className={css.saveForLaterUse}>
                  <FieldCheckbox
                    className={css.saveForLaterUseCheckbox}
                    textClassName={css.saveForLaterUseLabel}
                    id="saveAfterOnetimePayment"
                    name="saveAfterOnetimePayment"
                    label={labelText}
                    useSuccessColor
                  />
                  <span className={css.saveForLaterUseLegalInfo}>
                    <FormattedMessage id="StripePaymentForm.saveforLaterUseLegalInfo" />
                  </span>
                </div>
                : null}

              <FieldTextInput
                className={css.field}
                type="text"
                id={`CheckoutForm.name`}
                name={"name"}
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />

              <div className={css.inputRow}>
                <FieldTextInput
                  id={`CheckoutForm.zip`}
                  className={css.postalCode}
                  name={"zip"}
                  type={"text"}
                  label={postalCodeLabel}
                  autoComplete={"postal-code"}
                  validate={postalCodeRequired}
                  placeholder={postalCodePlaceholder}
                  onUnmount={() => form.change(`zip`, undefined)}
                />

                <FieldSelect
                  id={`CheckoutForm.country`}
                  name={"country"}
                  className={css.countryInput}
                  label={countryLabel}
                  validate={countryRequired}
                >
                  <option disabled value="">
                    {countryPlaceholder}
                  </option>
                  {countryCodes.map(country => {
                    return (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    );
                  })}
                </FieldSelect>
              </div>

              {error
                ? <div className="message sr-field-error">{error}</div>
                : null}

              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                className={css.submitButton}
              >
                {formId && formId == 'PaymentMethodsForm'
                  ? <FormattedMessage id="CheckoutForm.submitPaymentInfo" />
                  : <FormattedMessage id="CheckoutForm.addPaymentMethod" />}
                {/* <FormattedMessage id="QuoteRequestForm.requestAQuote" /> */}
              </PrimaryButton>
            </div> : (
              <PrimaryButton
                type={"button"}
                inProgress={submitInProgress}
                disabled={submitDisabled}
                onClick={() => checkoutSubmit(values)}
              >
                <FormattedMessage id="CheckoutForm.submitPaymentInfo" />
              </PrimaryButton>
            )}
          </Form>
        );
      }}
    />
  );
};

CheckoutFormComponent.defaultProps = { rootClassName: null, className: null };

CheckoutFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  // onSubmit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CheckoutForm = injectIntl(CheckoutFormComponent);

export default CheckoutForm;
