import omit from 'lodash/omit';
import moment from 'moment';
import uuid4 from 'uuid4';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  createStripeAccount,
  fetchStripeAccount,
  updateStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentCategories, fetchCurrentUser } from '../../ducks/user.duck';
import {
  createAlgoliaData,
  createTransaction,
  eventCreationApi,
  sendProposal
} from '../../util/api';
import { denormalisedResponseEntities, uploadCloudinaryFiles } from '../../util/data';
import { getDefaultTimeZoneOnBrowser, resetToStartOfDay } from '../../util/dates';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result?.data || null });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const MARK_TAB_UPDATED = 'app/EditListingPage/MARK_TAB_UPDATED';
export const CLEAR_UPDATED_TAB = 'app/EditListingPage/CLEAR_UPDATED_TAB';

export const CREATE_LISTING_REQUEST = 'app/EditListingPage/CREATE_LISTING_REQUEST';
export const CREATE_LISTING_SUCCESS = 'app/EditListingPage/CREATE_LISTING_SUCCESS';
export const CREATE_LISTING_ERROR = 'app/EditListingPage/CREATE_LISTING_ERROR';
export const CREATE_EVENT_SUCCESS = 'app/EditListingPage/CREATE_EVENT_SUCCESS';

export const CREATE_LISTING_DRAFT_REQUEST = 'app/EditListingPage/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS = 'app/EditListingPage/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR = 'app/EditListingPage/CREATE_LISTING_DRAFT_ERROR';

export const PUBLISH_LISTING_REQUEST = 'app/EditListingPage/PUBLISH_LISTING_REQUEST';
export const PUBLISH_LISTING_SUCCESS = 'app/EditListingPage/PUBLISH_LISTING_SUCCESS';
export const PUBLISH_LISTING_ERROR = 'app/EditListingPage/PUBLISH_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/EditListingPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/EditListingPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/EditListingPage/UPDATE_LISTING_ERROR';

export const SHOW_LISTINGS_REQUEST = 'app/EditListingPage/SHOW_LISTINGS_REQUEST';
export const SHOW_LISTINGS_SUCCESS = 'app/EditListingPage/SHOW_LISTINGS_SUCCESS';
export const SHOW_LISTINGS_ERROR = 'app/EditListingPage/SHOW_LISTINGS_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/EditListingPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/EditListingPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/EditListingPage/UPLOAD_IMAGE_ERROR';
export const CLEAR_UPLOAD_IMAGE_SUCCESS = 'app/EditListingPage/CLEAR_UPLOAD_IMAGE_SUCCESS';

export const UPDATE_IMAGE_ORDER = 'app/EditListingPage/UPDATE_IMAGE_ORDER';

export const REMOVE_LISTING_IMAGE = 'app/EditListingPage/REMOVE_LISTING_IMAGE';

export const FETCH_EXCEPTIONS_REQUEST = 'app/EditListingPage/FETCH_AVAILABILITY_EXCEPTIONS_REQUEST';
export const FETCH_EXCEPTIONS_SUCCESS = 'app/EditListingPage/FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS';
export const FETCH_EXCEPTIONS_ERROR = 'app/EditListingPage/FETCH_AVAILABILITY_EXCEPTIONS_ERROR';

export const ADD_EXCEPTION_REQUEST = 'app/EditListingPage/ADD_AVAILABILITY_EXCEPTION_REQUEST';
export const ADD_EXCEPTION_SUCCESS = 'app/EditListingPage/ADD_AVAILABILITY_EXCEPTION_SUCCESS';
export const ADD_EXCEPTION_ERROR = 'app/EditListingPage/ADD_AVAILABILITY_EXCEPTION_ERROR';

export const DELETE_EXCEPTION_REQUEST = 'app/EditListingPage/DELETE_AVAILABILITY_EXCEPTION_REQUEST';
export const DELETE_EXCEPTION_SUCCESS = 'app/EditListingPage/DELETE_AVAILABILITY_EXCEPTION_SUCCESS';
export const DELETE_EXCEPTION_ERROR = 'app/EditListingPage/DELETE_AVAILABILITY_EXCEPTION_ERROR';

export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/EditListingPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/EditListingPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/EditListingPage/SAVE_PAYOUT_DETAILS_ERROR';

// ================ Reducer ================ //

const initialState = {
  // Error instance placeholders for each endpoint
  createListingError: null,
  createListingDraftError: null,
  publishingListing: null,
  publishListingError: null,
  updateListingError: null,
  showListingsError: null,
  uploadImageError: null,
  createListingInProgress: false,
  createListingDraftInProgress: false,
  submittedListingId: null,
  redirectToListing: false,
  images: {},
  imageOrder: [],
  removedImageIds: [],
  fetchExceptionsError: null,
  fetchExceptionsInProgress: false,
  availabilityExceptions: [],
  addExceptionError: null,
  addExceptionInProgress: false,
  deleteExceptionError: null,
  deleteExceptionInProgress: false,
  listing: null,
  listingDraft: null,
  updatedTab: null,
  updateInProgress: false,
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MARK_TAB_UPDATED:
      return { ...state, updatedTab: payload };
    case CLEAR_UPDATED_TAB:
      return { ...state, updatedTab: null, updateListingError: null };

    case CREATE_LISTING_REQUEST:
      return {
        ...state,
        createListingInProgress: true,
        createListingError: null,
        submittedListingId: null,
        listing: null,
      };

    case CREATE_LISTING_SUCCESS:
      return {
        ...state,
        createListingInProgress: false,
        submittedListingId: payload.data.id,
        listing: payload.data,
      };
    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        createListingInProgress: false,
        submittedListingId: null,
        listing: null,
      };
    case CREATE_LISTING_ERROR:
      return {
        ...state,
        createListingInProgress: false,
        createListingError: payload,
      };

    case CREATE_LISTING_DRAFT_REQUEST:
      return {
        ...state,
        createListingDraftInProgress: true,
        createListingDraftError: null,
        submittedListingId: null,
        listingDraft: null,
      };

    case CREATE_LISTING_DRAFT_SUCCESS:
      return {
        ...state,
        createListingDraftInProgress: false,
        submittedListingId: payload.data.id,
        listingDraft: payload.data,
      };
    case CREATE_LISTING_DRAFT_ERROR:
      return {
        ...state,
        createListingDraftInProgress: false,
        createListingDraftError: payload,
      };

    case PUBLISH_LISTING_REQUEST:
      return {
        ...state,
        publishingListing: payload.listingId,
        publishListingError: null,
      };
    case PUBLISH_LISTING_SUCCESS:
      return {
        ...state,
        redirectToListing: true,
        publishingListing: null,
        createListingDraftError: null,
        updateListingError: null,
        showListingsError: null,
        uploadImageError: null,
        createListingDraftInProgress: false,
        updateInProgress: false,
      };
    case PUBLISH_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        publishingListing: null,
        publishListingError: {
          listingId: state.publishingListing,
          error: payload,
        },
      };
    }

    case UPDATE_LISTING_REQUEST:
      return { ...state, updateInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, updateInProgress: false };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload };

    case SHOW_LISTINGS_REQUEST:
      return { ...state, showListingsError: null };
    case SHOW_LISTINGS_SUCCESS:
      return { ...state, images: {}, imageOrder: [], removedImageIds: [] };

    case SHOW_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, showListingsError: payload, redirectToListing: false };

    case UPLOAD_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const images = {
        ...state.images,
        [payload.params.imageType ? payload.params.imageType : payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        images,
        uploadInProgress: true,
        imageOrder: state.imageOrder.concat([payload.params.id]),
        uploadImageError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id'}
      const { id, imageId, imageType, uploadedImage } = payload;
      const file = state.images[imageType ? imageType : id].file;
      const images = {
        ...state.images,
        [imageType ? imageType : id]: (imageType ? { id, imageId, uploadedImage, file } : { id, imageId, file })
      };
      return { ...state, images, uploadInProgress: false, };
    }
    case CLEAR_UPLOAD_IMAGE_SUCCESS: {
      return { ...state, images: {}, imageOrder: [], removedImageIds: [], };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const imageOrder = state.imageOrder.filter(i => i !== id);
      const images = omit(state.images, id);
      return { ...state, imageOrder, images, uploadImageError: true, uploadInProgress: false, };
    }
    case UPDATE_IMAGE_ORDER:
      return { ...state, imageOrder: payload.imageOrder };

    case REMOVE_LISTING_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.images[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const images = omit(state.images, id);
      const imageOrder = state.imageOrder.filter(i => i !== id);

      return { ...state, images, imageOrder, removedImageIds };
    }

    case FETCH_EXCEPTIONS_REQUEST:
      return {
        ...state,
        availabilityExceptions: [],
        fetchExceptionsError: null,
        fetchExceptionsInProgress: true,
      };
    case FETCH_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        availabilityExceptions: payload,
        fetchExceptionsError: null,
        fetchExceptionsInProgress: false,
      };
    case FETCH_EXCEPTIONS_ERROR:
      return {
        ...state,
        fetchExceptionsError: payload.error,
        fetchExceptionsInProgress: false,
      };

    case ADD_EXCEPTION_REQUEST:
      return {
        ...state,
        addExceptionError: null,
        addExceptionInProgress: true,
      };
    case ADD_EXCEPTION_SUCCESS: {
      let availabilityExceptions = state.availabilityExceptions;
      if (payload == 'CLEAR') {
        availabilityExceptions = [];
      } else {
        availabilityExceptions.push(payload);
      }
      return {
        ...state,
        availabilityExceptions,
        addExceptionInProgress: false,
      };
    }
    case ADD_EXCEPTION_ERROR:
      return {
        ...state,
        addExceptionError: payload.error,
        addExceptionInProgress: false,
      };

    case DELETE_EXCEPTION_REQUEST:
      return {
        ...state,
        deleteExceptionError: null,
        deleteExceptionInProgress: true,
      };
    case DELETE_EXCEPTION_SUCCESS: {
      const deletedExceptionId = payload.id;
      const availabilityExceptions = state.availabilityExceptions.filter(
        e => e.id.uuid !== deletedExceptionId.uuid
      );
      return {
        ...state,
        availabilityExceptions,
        deleteExceptionInProgress: false,
      };
    }
    case DELETE_EXCEPTION_ERROR:
      return {
        ...state,
        deleteExceptionError: payload.error,
        deleteExceptionInProgress: false,
      };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const markTabUpdated = tab => ({
  type: MARK_TAB_UPDATED,
  payload: tab,
});

export const clearUpdatedTab = () => ({
  type: CLEAR_UPDATED_TAB,
});

export const updateImageOrder = imageOrder => ({
  type: UPDATE_IMAGE_ORDER,
  payload: { imageOrder },
});

export const removeListingImage = imageId => ({
  type: REMOVE_LISTING_IMAGE,
  payload: { imageId },
});

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

// SDK method: ownListings.create
export const createListing = requestAction(CREATE_LISTING_REQUEST);
export const createListingSuccess = successAction(CREATE_LISTING_SUCCESS);
export const createListingError = errorAction(CREATE_LISTING_ERROR);
export const createEventSuccess = errorAction(CREATE_EVENT_SUCCESS);

export const createListingDraft = requestAction(CREATE_LISTING_DRAFT_REQUEST);
export const createListingDraftSuccess = successAction(CREATE_LISTING_DRAFT_SUCCESS);
export const createListingDraftError = errorAction(CREATE_LISTING_DRAFT_ERROR);

// SDK method: ownListings.publish
export const publishListing = requestAction(PUBLISH_LISTING_REQUEST);
export const publishListingSuccess = successAction(PUBLISH_LISTING_SUCCESS);
export const publishListingError = errorAction(PUBLISH_LISTING_ERROR);

// SDK method: ownListings.update
export const updateListing = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);

// SDK method: ownListings.show
export const showListings = requestAction(SHOW_LISTINGS_REQUEST);
export const showListingsSuccess = successAction(SHOW_LISTINGS_SUCCESS);
export const showListingsError = errorAction(SHOW_LISTINGS_ERROR);

// SDK method: images.upload
export const uploadImage = requestAction(UPLOAD_IMAGE_REQUEST);
export const initialClearUploadImage = successAction(CLEAR_UPLOAD_IMAGE_SUCCESS);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

// SDK method: availabilityExceptions.query
export const fetchAvailabilityExceptionsRequest = requestAction(FETCH_EXCEPTIONS_REQUEST);
export const fetchAvailabilityExceptionsSuccess = successAction(FETCH_EXCEPTIONS_SUCCESS);
export const fetchAvailabilityExceptionsError = errorAction(FETCH_EXCEPTIONS_ERROR);

// SDK method: availabilityExceptions.create
export const addAvailabilityExceptionRequest = requestAction(ADD_EXCEPTION_REQUEST);
export const addAvailabilityExceptionSuccess = successAction(ADD_EXCEPTION_SUCCESS);
export const addAvailabilityExceptionError = errorAction(ADD_EXCEPTION_ERROR);

// SDK method: availabilityExceptions.delete
export const deleteAvailabilityExceptionRequest = requestAction(DELETE_EXCEPTION_REQUEST);
export const deleteAvailabilityExceptionSuccess = successAction(DELETE_EXCEPTION_SUCCESS);
export const deleteAvailabilityExceptionError = errorAction(DELETE_EXCEPTION_ERROR);

export const savePayoutDetailsRequest = requestAction(SAVE_PAYOUT_DETAILS_REQUEST);
export const savePayoutDetailsSuccess = successAction(SAVE_PAYOUT_DETAILS_SUCCESS);
export const savePayoutDetailsError = errorAction(SAVE_PAYOUT_DETAILS_ERROR);

// ================ Thunk ================ //

export function requestShowListing(actionPayload) {
  return (dispatch, getState, sdk) => {
    dispatch(showListings(actionPayload));
    return sdk.ownListings
      .show(actionPayload)
      .then(response => {
        // EditListingPage fetches new listing data, which also needs to be added to global data
        if (response.data && response.data.data && Object.keys(response.data.data).length && response.data.data.attributes.publicData.categories && response.data.data.attributes.publicData.categories.length) {
          const subCategoriesId = [];
          response.data.data.attributes.publicData.categories.map(cat => subCategoriesId.push(cat.id));
          dispatch(fetchCurrentCategories({ type: "subCategories", parentIds: subCategoriesId }));
        }
        dispatch(addMarketplaceEntities(response));
        dispatch(showListingsSuccess(response));
        return response;
      })
      .catch(e => dispatch(showListingsError(storableError(e))));
  };
}

export function requestCreateListingDraft(data) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingDraft(data));

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };

    return sdk.ownListings
      .createDraft(data, queryParams)
      .then(response => {
        // const id = response.data.data.id.uuid;

        // Add the created listing to the marketplace data
        dispatch(addMarketplaceEntities(response));

        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingDraftSuccess(response));
        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: data });
        return dispatch(createListingDraftError(storableError(e)));
      });
  };
}

export const requestPublishListingDraft = listingId => (dispatch, getState, sdk) => {
  dispatch(publishListing(listingId));

  return sdk.ownListings
    .publishDraft({ id: listingId }, { expand: true })
    .then(response => {
      const id = response.data.data.id.uuid;
      const { currentUser } = getState().user;
      const { firstName, lastName, email, displayName, publicData: userPublicData } = currentUser.attributes.profile;
      const { title = "", description = "", state, price, geolocation, publicData = {} } = response.data.data.attributes || {};

      createAlgoliaData({
        indexName: process.env.REACT_APP_ALGOLIA_EVENT_INDEX,
        objectID: id,
        state,
        createdAt: moment().unix(),
        _geoloc: geolocation && geolocation.lat ? { lat: geolocation.lat, lng: geolocation.lng } : {},
        title, description, price,
        ...publicData,
        user: { firstName, lastName, email, displayName, ...userPublicData }
      });
      createTransaction({
        tableName: "requests",
        payload: {
          listingId: id,
          status: 'REQUESTED',
          createdAt: moment().unix(),
        }
      });
      // sendMail({
      //   to: {
      //     name: (firstName + ' ' + lastName),
      //     email
      //   },
      //   dynamic_template_data: {
      //     MarketPlaceURL: (process.env.ROOT_URL + '/my-events')
      //   },
      //   type: "QUOTES_CREATION",
      // });

      // Add the created listing to the marketplace data
      dispatch(addMarketplaceEntities(response));
      dispatch(publishListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(publishListingError(storableError(e)));
    });
};

// Images return imageId which we need to map with previously generated temporary id
export const requestImageUpload = (actionPayload) => async (dispatch, getState, sdk) => {
  const uploadPromises = [];
  if (actionPayload.files && actionPayload.files.length) {
    for await (const file of actionPayload.files) {
      const id = `${file.name}_${Date.now()}`;
      dispatch(uploadImage({ id, file }));
      const queryParams = {
        expand: true,
        'fields.image': ['variants.default', 'variants.square-small', 'variants.square-small2x', 'variants.landscape-crop'],
      };
      uploadPromises.push(sdk.images
        .upload({ image: file }, queryParams)
        .then(resp => {
          dispatch(uploadImageSuccess({
            data: {
              id, imageId: resp.data.data.id,
              uploadedImage: resp.data.data,
              imageType: actionPayload.imageType
            }
          }));
          return resp.data.data.id;
        })
        .catch(e => dispatch(uploadImageError({ id, error: storableError(e) }))));
    }
    return await Promise.all(uploadPromises);
  } else {
    const id = actionPayload.id;
    dispatch(uploadImage(actionPayload));
    const queryParams = {
      expand: true,
      'fields.image': ['variants.default', 'variants.square-small', 'variants.square-small2x', 'variants.landscape-crop'],
    };
    return sdk.images
      .upload({ image: actionPayload.file }, queryParams)
      .then(resp => {
        dispatch(uploadImageSuccess({
          data: {
            id,
            imageId: resp.data.data.id,
            uploadedImage: resp.data.data,
            imageType: actionPayload.imageType,
          }
        }));
        return resp.data.data.id;
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  }
}

// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.
export function requestCreateListing(data) {
  return (dispatch, getState, sdk) => {
    dispatch(createListing(data));

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };
    const { currentUser, currentUserHasListings } = getState().user;

    return (currentUser && currentUser.id && currentUserHasListings)
      ? sdk.ownListings
        .create(data, queryParams)
        .then(response => {
          const id = response.data.data.id;
          const { publicData: { vendorId, nearByVendor }, ...rest } = (response.data.data.attributes) || {};

          if (vendorId) {
            sendProposal({ listingId: id.uuid, vendorId: vendorId }); 
          }
          if (nearByVendor) {
            createTransaction({
              tableName: "requests",
              payload: {
                listingId: id.uuid,
                status: 'REQUESTED',
                createdAt: moment().unix(),
              }
            });
          }

          if(typeof window !== "undefined"){
            window.sessionStorage.removeItem('quoteForm');
            window.sessionStorage.removeItem('path');
          }

          // Add the created listing to the marketplace data
          dispatch(addMarketplaceEntities(response));
          dispatch(updateImageOrder([]));
          // Modify store to understand that we have created listing and can redirect away
          dispatch(createListingSuccess(response));
          return response;
        })
        .catch(e => {
          log.error(e, 'create-listing-failed', { listingData: data });
          return dispatch(createListingError(storableError(e)));
        })
      : eventCreationApi(data)
        .then(res => {
          // Modify store to understand that we have created listing and can redirect away
          dispatch(createEventSuccess(res));
          return res;
        })
        .catch(e => {
          log.error(e, 'create-listing-failed', { listingData: data });
          return dispatch(createListingError(storableError(e)));
        });
  };
}

export const requestUpdateListing = (tab, data) => async (dispatch, getState, sdk) => {
  dispatch(updateListing(data));
  const { id } = data;

  if (tab == 'style') {
    const { currentUser } = getState().user;
    const { images } = getState().EditListingPage;

    if (images && Object.keys(images).length) {
      const files = [], imageIds = [];
      for (const key in images) {
        if (Object.prototype.hasOwnProperty.call(images, key)) {
          const element = images[key];
          imageIds.push(element.imageId.uuid);
          files.push(element.file);
        }
      }
      const uploadedUrl = await uploadCloudinaryFiles(files, currentUser.id.uuid);

      if (uploadedUrl && uploadedUrl.length) {
        const uploadedImagesUrls = (data.publicData && data.publicData.uploadedImagesUrls) || [];

        uploadedUrl.map((url, ind) => {
          if (imageIds && imageIds[ind]) {
            uploadedImagesUrls.push({
              id: imageIds[ind],
              url: url.data.secure_url
            });
          }

          Object.assign(data.publicData, { uploadedImagesUrls });
        });
        dispatch(initialClearUploadImage());
      }
    }
  }

  let updateResponse;
  return sdk.ownListings
    .update(data)
    .then(response => {
      updateResponse = response;
      const payload = {
        id,
        include: ['author', 'images'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      };
      return dispatch(requestShowListing(payload));
    })
    .then(() => {
      dispatch(markTabUpdated(tab));
      dispatch(updateListingSuccess(updateResponse));
      return updateResponse;
    })
    .catch(e => {
      log.error(e, 'update-listing-failed', { listingData: data });
      dispatch(updateListingError(storableError(e)));
      throw e;
    });
}

export const requestAddAvailabilityException = params => (dispatch, getState, sdk) => {
  dispatch(addAvailabilityExceptionRequest(params));
  if (params.listingId) {
    return sdk.availabilityExceptions
      .create(params, { expand: true })
      .then(response => {
        const availabilityException = response.data.data;
        return dispatch(addAvailabilityExceptionSuccess({ data: availabilityException }));
      })
      .catch(e => {
        dispatch(addAvailabilityExceptionError({ error: storableError(e) }));
        throw e;
      });
  } else {
    return Promise.all([dispatch(addAvailabilityExceptionSuccess({
      data: {
        id: new UUID(uuid4()),
        type: "availabilityException",
        attributes: params
      }
    }))]);
  }
};

export const requestClearAvailabilityException = () => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(addAvailabilityExceptionSuccess({
    data: "CLEAR"
  }))]);
}

export const requestDeleteAvailabilityException = params => (dispatch, getState, sdk) => {
  dispatch(deleteAvailabilityExceptionRequest(params));

  if (params.isLocal) {
    return Promise.all([dispatch(deleteAvailabilityExceptionSuccess({
      data: {
        ...params
      }
    }))]);
  }
  return sdk.availabilityExceptions
    .delete(params, { expand: true })
    .then(response => {
      const availabilityException = response.data.data;
      return dispatch(deleteAvailabilityExceptionSuccess({ data: availabilityException }));
    })
    .catch(e => {
      dispatch(deleteAvailabilityExceptionError({ error: storableError(e) }));
      throw e;
    });
};

export const requestFetchAvailabilityExceptions = fetchParams => (dispatch, getState, sdk) => {
  dispatch(fetchAvailabilityExceptionsRequest(fetchParams));

  return sdk.availabilityExceptions
    .query(fetchParams, { expand: true })
    .then(response => {
      const availabilityExceptions = denormalisedResponseEntities(response);
      return dispatch(fetchAvailabilityExceptionsSuccess({ data: availabilityExceptions }));
    })
    .catch(e => {
      return dispatch(fetchAvailabilityExceptionsError({ error: storableError(e) }));
    });
};

export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = params => (dispatch, getState, sdk) => {
  dispatch(clearUpdatedTab());
  dispatch(requestClearAvailabilityException());
  dispatch(initialClearUploadImage({}));
  const { id, type } = params;

  if (type === 'new') {
    // No need to listing data when creating a new listing
    return dispatch(fetchCurrentUser())
      .then(currentUser => {
        if (currentUser && currentUser.stripeAccount) {
          dispatch(fetchStripeAccount());
        }
        return currentUser;
      })
      .catch(e => {
        throw e;
      });
  }

  const payload = {
    id: new UUID(id),
    include: ['author', 'images'],
    'fields.image': ['variants.default', 'variants.landscape-crop', 'variants.landscape-crop2x'],
  };

  return Promise.all([dispatch(requestShowListing(payload)), dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccount());
      }

      // Because of two dispatch functions, response is an array.
      // We are only interested in the response from requestShowListing here,
      // so we need to pick the first one
      if (response && response.length && response[0].data && response[0].data.data) {
        const listing = response[0].data.data;

        // If the listing doesn't have availabilityPlan yet
        // use the defaul timezone
        const availabilityPlan = listing.attributes.availabilityPlan;

        const tz = availabilityPlan
          ? listing.attributes.availabilityPlan.timezone
          : typeof window !== 'undefined'
            ? getDefaultTimeZoneOnBrowser()
            : 'Etc/UTC';

        const today = new Date();
        const start = resetToStartOfDay(today, tz, 0);
        // Query range: today + 364 days
        const exceptionRange = 364;
        const end = resetToStartOfDay(today, tz, exceptionRange);

        // NOTE: in this template, we don't expect more than 100 exceptions.
        // If there are more exceptions, pagination kicks in and we can't use frontend sorting.
        const params = {
          listingId: listing.id,
          start,
          end,
        };
        dispatch(requestFetchAvailabilityExceptions(params));
      }

      return response;
    })
    .catch(e => {
      throw e;
    });
};
