import React, { useState } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';

import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { parse, stringify } from '../../util/urlHelpers';
import { parseToFromSharetribe } from '../../util/data';
import { getDefaultTimeZoneOnBrowser, timestampToDate } from '../../util/dates';
import { createResourceLocatorString } from '../../util/routes';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';

import config from '../../config';
import routeConfiguration from '../../routeConfiguration';

import {
  ModalInMobile,
  Button,
  PrimaryButton,
  SecondaryButton,
  IconProfileSetup,
  Modal
} from '../../components';
import {
  BookingTimeForm,
  EditListingAvailabilityExceptionForm,
  QuoteRequestForm
} from '../../forms';

import quoteLogo from '../../assets/quoteLogo.png';
import modallogoImage from '../../assets/modallogo.png';

import css from './BookingPanel.module.css';

const { Money, LatLng, UUID } = sdkTypes;
// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();
const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

// Ensure that the AvailabilityExceptions are in sensible order.
//
// Note: if you allow fetching more than 100 exception,
// pagination kicks in and that makes client-side sorting impossible.
const sortExceptionsByStartTime = (a, b) => {
  return a.attributes.start.getTime() - b.attributes.start.getTime();
};

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    editParams,
    onSubmit,
    title,
    subTitle,
    isAuthenticated,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    userType,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    onOpenListing,
    onCloseListing,
    openingListingInProgress,
    closingListingInProgress,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    setFinishModalOpen,
    quoteRequestModalOpen,
    setQuoteRequestModalOpen,
    quoteRequestMobileModalOpen,
    setQuoteRequestMobileModalClose,

    errors,
    images,
    currentUser,
    currentUserListing,
    authorDisplayName,
    categories: oldCategories,
    onImageUpload,
    updateInProgress,
    onRemoveListingImage,
    availabilityExceptions,
    fetchExceptionsInProgress,
    onAddAvailabilityException,
    onClearAvailabilityException,
    onDeleteAvailabilityException,
    onCreateListing,
    eventTypes,
    currentUserHasListings
  } = props;

  const prefilledQuoteFromValues = window.sessionStorage.getItem('quoteForm');
  const [showSubmitModal, toggleSubmitModal] = useState(false);
  const [eventDuration, setEventDuration] = useState(false);
  const [quoteFormValues, setQuoteValues] = useState({ eventDuration: 'singleDay' });
  const [isEditExceptionsModalOpen, setIsEditExceptionsModalOpen] = useState(false);
  const { createListingError, createListingInProgress } = useSelector(state => state.EditListingPage);
  const price = listing.attributes.price;
  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;
  const [successModalData, setsuccessModalData] = useState({});

  if (prefilledQuoteFromValues) {
    typeof window !== 'undefined' && window.sessionStorage.removeItem('quoteForm');
    typeof window !== 'undefined' && window.sessionStorage.removeItem('path');
    const valuesObj = JSON.parse(prefilledQuoteFromValues);
    for (const key in valuesObj) {
      const elem = valuesObj[key];
      if (elem && key.includes('Price')) {
        valuesObj[key] = new Money(elem.amount ?? 0, config.currency);
      }
      if (elem && key === 'location') {
        elem.selectedPlace.origin = new LatLng(elem.selectedPlace.origin.lat, elem.selectedPlace.origin.lng);
        elem.selectedPlace.bounds = null;
      }

    }
    setQuoteValues(valuesObj);
  }

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
      ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
      : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
      ? 'BookingPanel.perDay'
      : 'BookingPanel.perUnit';

  const defaultAvailabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      // { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: 1 },
    ],
  };
  const availabilityPlan = defaultAvailabilityPlan;

  // Save exception click handler
  const saveException = values => {
    const { availability, exceptionStartTime, exceptionEndTime } = values;

    // TODO: add proper seat handling
    const seats = 1; // availability === 'available' ? 1 : 0;

    // if (availabilityExceptions && availabilityExceptions.length) {
    //   availabilityExceptions.forEach(ae => onDeleteAvailabilityException({
    //     id: new UUID(ae.availabilityExceptionId)
    //   }));
    // };
    return onAddAvailabilityException({
      // listingId: listing.id,
      seats,
      start: timestampToDate(exceptionStartTime),
      end: timestampToDate(exceptionEndTime),
    })
      .then(() => {
        setIsEditExceptionsModalOpen(false);
        return false;
      })
      .catch(e => {
        // Don't close modal if there was an error
        return false;
      });
  };

  const exceptionCount = availabilityExceptions ? availabilityExceptions.length : 0;
  const sortedAvailabilityExceptions = availabilityExceptions.sort(sortExceptionsByStartTime);

  const classes = classNames(rootClassName || css.root, className);


  const renderQuoteFormInMobileAndDesktop = () => {
    if (isOwnListing) {

      const { isCompletedSteps } = currentUser.attributes.profile?.publicData || {};
      const { faqs = [], services = [] } = listing.attributes.publicData || {};

      let progress = 25;

      if (isCompletedSteps) {
        progress += 25;
      }
      if (faqs.length) {
        progress += 25;
      }
      if (services.length) {
        progress += 25;
      }

      return (<div className={css.bookingWrapper}>
        <div className={css.listView}>
          <progress className={css.profileProgress} value={progress} max={100} />
          <p className={css.progressText}>Your Business Profile is {progress}% complete</p>

          {progress == 100
            ? null
            : <>
              <p className={css.listText} onClick={() => setFinishModalOpen('SERVICE')} >
                <FormattedMessage id="BookingPanel.listServices" />
                + List Your Services
              </p>
              <p className={css.listText} onClick={() => setFinishModalOpen('FAQ')}>
                <FormattedMessage id="BookingPanel.addFaqs" />
                + Add FAQ Section
              </p>
            </>}
        </div>
        <PrimaryButton
          className={css.editDetails}
          onClick={() => history.push(createResourceLocatorString('ProfileSetupBasePage', routeConfiguration()))}
        >
          <FormattedMessage id="BookingPanel.editListingButton" />
        </PrimaryButton>
        <SecondaryButton
          className={css.closeDetails}
          inProgress={isClosed
            ? openingListingInProgress
            : closingListingInProgress}
          onClick={() => isClosed
            ? onOpenListing(listing.id)
            : onCloseListing(listing.id)}
        >
          {isClosed
            ? <FormattedMessage id="BookingPanel.openedListingButton" />
            : <FormattedMessage id="BookingPanel.closedListingButton" />}
        </SecondaryButton>
      </div>)
    }

    return (<div>
      <QuoteRequestForm
        className={css.form}
        initialValues={quoteFormValues}
        currentUserHasListings={currentUserHasListings}
        listingId={listing.id.uuid}
        onChange={(e) => setQuoteValues(e.values)}
        updateInProgress={createListingInProgress}
        updateProfileError={createListingError}
        isAuthenticated={isAuthenticated}
        history={history}
        userType={userType}
        onSubmit={values => {
          const {
            title,
            building = '',
            addImage,
            location,
            eventSize,
            eventType,
            nearByVendor,
            eventDuration,
            eventSizeLabel,
            optionalMessage,
            uploadedImagesUrls = [],
            ...rest
          } = values;

          setsuccessModalData({ eventType, nearByVendor });
          setQuoteValues({ eventDuration: 'singleDay' }); //, nearByVendor: true

          const { categories = [], price } = parseToFromSharetribe({ ...rest }, oldCategories, null, 'QUOTE_FORM');
          const checkHasImage = !!(typeof eventType == "object" && Object.keys(eventType).length && Object.hasOwn(eventType, "image"));
          const eventHasImageURL = !!(typeof eventType == "object" && Object.keys(eventType).length && Object.hasOwn(eventType, "imageURL"));
          const hasEventTypes = !!(Array.isArray(eventTypes) && eventTypes.length);
          // const eventTypeImageURL = eventType && eventType.image
          //   ? eventType.image
          //   : eventTypes && eventTypes.length
          //     ? eventTypes.find(et => et.name == eventType).image
          //     : '';

          const eventTypeImageURL = checkHasImage ? eventType.image : eventHasImageURL ? eventType.imageURL : hasEventTypes ? eventTypes.find(et => et.name == eventType).image : '';

          const {
            selectedPlace: { address, origin, city, region, postcode },
          } = location;
          const imageIds = images => {
            return images ? images.map(img => img.imageId || img.id) : null;
          };
          const dummyTitle = (currentUserListing && currentUserListing.id && currentUserListing.attributes.publicData.businessName) || (moment().unix() + '')
          const updateValues = {
            // as per ticket no VTD-195
            title: dummyTitle,
            // `${currentUser.attributes.profile.displayName}${categories[0] ? '-' + categories[0].label : ''}`,
            geolocation: origin,
            images: imageIds(images),
            publicData: {
              vendorId: listing.id.uuid,
              ListingType: 'EVENT',
              location: { address, building },
              city,
              region,
              postcode,
              nearByVendor,
              eventDuration,
              eventSize,
              eventType,
              eventSizeLabel,
              optionalMessage,
              eventTypeImageURL,
            },
          };
          if (price) {
            Object.assign(updateValues, { price });
          }
          if (eventSize && eventSize.label) {
            // eventSize
            Object.assign(updateValues.publicData, {
              eventSizeLabel: eventSize.label
            });
          }
          const subCategoriesLabel = [],
            categoriesLabel = categories && categories.length
              ? categories.map(d => {
                if (d.subCategory && d.subCategory.length) {
                  subCategoriesLabel.push(...d.subCategory.map(s => s.label));
                }
                return d.label;
              })
              : [];
          Object.assign(updateValues.publicData, {
            categories,
            datesCount: exceptionCount,
            categoriesLabel,
            subCategoriesLabel,
            uploadedImagesUrls,
            eventDates: sortedAvailabilityExceptions.map(sae => ({
              start: sae.attributes.start.getTime(),
              end: sae.attributes.end.getTime()
            })),
          });
          if (images && images.length) {
            updateValues.publicData.uploadedImagesUrls = updateValues.publicData.uploadedImagesUrls.filter(i => images.findIndex(ri => ri.id && ri.id.uuid && (ri.id.uuid == i.id)) > -1);
            updateValues.publicData.uploadedImagesUrls.push(...images.filter(ri => ri.imageId).map(ri => ({ id: ri.imageId.uuid, url: ri.secure_url })));
          }
          return onCreateListing(updateValues).then(res => {
            const insertedId = (res.data.insertedId) || null;

            toggleSubmitModal(true);
            setQuoteValues({ eventDuration: 'singleDay' });
            onClearAvailabilityException();
            if (res && res.data && res.data.insertedId && typeof window != 'undefined') {
              window.sessionStorage.setItem('eventCreation', res.data.insertedId);
            }

            if (!isAuthenticated && typeof window != 'undefined') {
              window.sessionStorage.setItem('quoteForm', JSON.stringify(values));
              window.sessionStorage.setItem('path', window.location.pathname);
              // window.sessionStorage.setItem('eventCreation', insertedId);
              return history.push(createResourceLocatorString('SignupPage', routeConfiguration()));
            } else if (isAuthenticated && !currentUserHasListings && typeof window != 'undefined') {
              window.sessionStorage.setItem('quoteForm', JSON.stringify(values));
              window.sessionStorage.setItem('path', window.location.pathname);
              window.sessionStorage.setItem('eventCreation', insertedId);
              return history.push(createResourceLocatorString('ProfileSetupPage', routeConfiguration(), { step: 'basics' }, {}));
            }
          });
        }}
        setQuoteRequestModalOpen={setQuoteRequestModalOpen}
        WEEKDAYS={WEEKDAYS}
        images={images || []}
        categories={oldCategories}
        onImageUpload={onImageUpload}
        onRemoveImage={onRemoveListingImage}
        availabilityPlan={availabilityPlan}
        exceptionCount={exceptionCount}
        fetchExceptionsInProgress={fetchExceptionsInProgress}
        setIsEditExceptionsModalOpen={(e) => {
          if (eventDuration != e && e == "singleDay") {
            onClearAvailabilityException();
          }
          setIsEditExceptionsModalOpen(e);
          setEventDuration(e);
        }}
        sortedAvailabilityExceptions={sortedAvailabilityExceptions}
        onDeleteAvailabilityException={onDeleteAvailabilityException}
        eventTypes={eventTypes}
      />
    </div>)
  };

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {renderQuoteFormInMobileAndDesktop()}
      </ModalInMobile>
      <Modal
        id="BookingTimeFormInModal"
        isOpen={quoteRequestMobileModalOpen}
        onClose={setQuoteRequestMobileModalClose}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        {renderQuoteFormInMobileAndDesktop()}
      </Modal>
      <Modal
        id="QuoteSubmitSuccessModal"
        isOpen={showSubmitModal}
        onClose={() => toggleSubmitModal(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
        className={css.quoteRequestModal}
      >
        <h3><FormattedMessage id="BookingPanel.yourQuoteRequest" /></h3>
        <p className={css.createdQuote}><FormattedMessage id="BookingPanel.yourQuoteCreated" values={{ categoriesLabel: listing.attributes?.publicData?.categoriesLabel, eventType: successModalData.eventType }} /></p>
        <p><FormattedMessage id="BookingPanel.yourQuoteSend" values={{ nearByVendor: successModalData.nearByVendor ? listing.attributes.title : 'all vendor Matched to your requirements' }} /></p>

        <PrimaryButton onClick={() => history.push(createResourceLocatorString((isAuthenticated ? 'AlgoliaSearchPage' : 'SignupPage'), routeConfiguration(), {}))}><FormattedMessage id="BookingPanel.continueBrowsingVendors" /></PrimaryButton>
        <SecondaryButton onClick={() => [toggleSubmitModal(false), !isAuthenticated && history.push(createResourceLocatorString(('SignupPage'), routeConfiguration(), {}))]}><FormattedMessage id="BookingPanel.soundsGood" /></SecondaryButton>

      </Modal>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>

        {showBookingTimeForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>

      <Modal
        id="EditAvailabilityExceptions"
        isOpen={!!isEditExceptionsModalOpen}
        onClose={() => setIsEditExceptionsModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainer}
        usePortal
        className={css.locationTimeModal}
      >
        <EditListingAvailabilityExceptionForm
          formId="EditListingAvailabilityExceptionForm"
          onSubmit={saveException}
          timeZone={availabilityPlan.timezone}
          availabilityExceptions={sortedAvailabilityExceptions}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
      </Modal>
      <Modal
        id="BookingPanel.showBookingTimeForm"
        contentClassName={css.finishModalContent}
        isOpen={quoteRequestModalOpen}
        modalTitle={intl.formatMessage({ id: 'BookingPanel.modalTitle' })}
        modalLogo={modallogoImage}
        onClose={() => setQuoteRequestModalOpen(false)}
        quoteRequestLogo={quoteLogo}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        ) : null}
      </Modal>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
