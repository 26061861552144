/**
 * Export loadData calls from ducks modules of different containers
*/
import { loadData as AlgoliaSearchPageLoader } from './AlgoliaSearchPage/AlgoliaSearchPage.duck';
import { loadData as AuthenticationPageLoader } from './AuthenticationPage/AuthenticationPage.duck';
import { loadData as ChatPageLoader } from './ChatPage/ChatPage.duck';
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as CMSPageLoader } from './CMSPage/CMSPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as InvoicePageLoader } from './InvoicePage/InvoicePage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as PreferredVendorsPageLoader } from './PreferredVendorsPage/PreferredVendorsPage.duck';
import { loadData as PrivacyPolicyPageLoader } from './PrivacyPolicyPage/PrivacyPolicyPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as ProfileSetupPageLoader } from './ProfileSetupPage/ProfileSetupPage.duck';
import { loadData as ProposalRequestsPageLoader } from './ProposalRequestsPage/ProposalRequestsPage.duck';
import { loadData as PurchaseAcornsPageLoader } from './PurchaseAcornsPage/PurchaseAcornsPage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as TermsOfServicePageLoader } from './TermsOfServicePage/TermsOfServicePage.duck';
import { loadData as TrustapPageLoader } from './TrustapPage/TrustapPage.duck';
import {
  setInitialValues as TransactionPageInitialValues,
  loadData as TransactionPageLoader,
} from './TransactionPage/TransactionPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    AlgoliaSearchPage: {
      loadData: AlgoliaSearchPageLoader,
    },
    AuthenticationPage: {
      loadData: AuthenticationPageLoader,
    },
    ChatPage: {
      loadData: ChatPageLoader
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    CMSPage: {
      loadData: CMSPageLoader,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    InvoicePage: {
      loadData: InvoicePageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    PrivacyPolicyPage: {
      loadData: PrivacyPolicyPageLoader,
    },
    PreferredVendorsPage: {
      loadData: PreferredVendorsPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    ProfileSetupPage: {
      loadData: ProfileSetupPageLoader,
    },
    ProposalRequestsPage: {
      loadData: ProposalRequestsPageLoader,
    },
    PurchaseAcornsPage: {
      loadData: PurchaseAcornsPageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    TermsOfServicePage: {
      loadData: TermsOfServicePageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    TrustapPage: {
      loadData: TrustapPageLoader
    }
  };
};

export default getPageDataLoadingAPI;
