import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import config from '../../config';

import routeConfiguration from '../../routeConfiguration';

import { formatMoney } from '../../util/currency';
import { isUploadImageOverLimitError } from '../../util/errors';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT } from '../../util/types';
import * as validators from '../../util/validators';

import {
  Button,
  FieldCheckbox,
  FieldCurrencyInput,
  FieldMultiSelect,
  FieldRadioButton,
  FieldTextInput,
  Form,
  IconClose,
  IconProfileSetup,
  IconSpinner,
  LocationAutocompleteInputField,
  PrimaryButton
} from '../../components';

import css from './QuoteRequestForm.module.css';

// const ACCEPT_IMAGES = 'image/*';
// const KEY_CODE_ENTER = 13;
const identity = v => v;
const { Money } = sdkTypes;

export class QuoteRequestFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { imageUploadRequested: false, submitted: false };
    this.handleDrop = this.handleDrop.bind(this);
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
  }

  onImageUploadHandler = async (files) => {
    if (files && files.length) {
      this.setState({ imageUploadRequested: true });
      this.props.onImageUpload({ files })
        .then(() => this.setState({ imageUploadRequested: false }));
    }
  }

  handleDrop(files) {
    if (files && files.length) {
      const filetype = files[0].type;
      if (filetype.search('image') > -1) {
        this.onImageUploadHandler(files);
      }
    }
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        handleDrop={this.handleDrop}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        mutators={{ ...arrayMutators }}
        // keepDirtyOnReinitialize={true}
        // onFormChange={this.props.onChange}
        // initialValues={{ images: this.props.images }}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            handleSubmit,
            invalid,
            intl,
            form,
            values,
            eventsOptions,
            eventSizesOptions,
            // setQuoteRequestModalOpen,
            // onFormChange,
            images,
            fetchErrors,
            history,
            categories,
            // handleDrop,
            // onFetchCurrentCategories,
            onRemoveImage,
            exceptionCount,
            availabilityPlan,
            updateInProgress,
            // imageUploadRequested,
            onImageUploadHandler,
            sortedAvailabilityExceptions,
            fetchExceptionsInProgress,
            onDeleteAvailabilityException,
            setIsEditExceptionsModalOpen,
            isAuthenticated,
            eventTypes,
            userType,
            currentUserHasListings,
            listingId
          } = fieldRenderProps;

          // events
          const eventsLabel = intl.formatMessage({
            id: 'QuoteRequestForm.eventsText',
          });
          const eventsPlaceholder = intl.formatMessage({
            id: 'QuoteRequestForm.eventsTextPlaceholder',
          });
          const eventsRequiredMessage = intl.formatMessage({
            id: 'QuoteRequestForm.eventsRequired',
          });
          const eventsRequired = validators.required(eventsRequiredMessage);

          // eventSize
          const eventSizeLabel = intl.formatMessage({
            id: 'QuoteRequestForm.estimateMessageLabel',
          });
          const eventSizePlaceholder = intl.formatMessage({
            id: 'QuoteRequestForm.eventSizePlaceholder',
          });
          const eventSizeRequiredMessage = intl.formatMessage({
            id: 'VendorAccessibilityForm.eventSizeRequired',
          });
          const eventSizeRequired = validators.required(eventSizeRequiredMessage);

          // nearByVendorLabel
          const nearByVendorLabel = intl.formatMessage({
            id: 'QuoteRequestForm.nearByVendorLabel',
          });
          // optionalMessage
          const optionalMessageLabel = intl.formatMessage({
            id: 'QuoteRequestForm.optionalMessageLabel',
          });
          const optionalMessagePlaceholder = intl.formatMessage({
            id: 'QuoteRequestForm.optionalMessagePlaceholder',
          });
          const optionalMessageRequiredMessage = intl.formatMessage({
            id: 'QuoteRequestForm.estimatedMessageRequired',
          });
          const optionalMessageRequired = validators.required(optionalMessageRequiredMessage);
          const titleRequiredMessage = intl.formatMessage({ id: 'QuoteRequestForm.location' });
          const addressRequiredMessage = intl.formatMessage({
            id: 'BookingTimeForm.addressRequired',
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: 'BookingTimeForm.addressNotRecognized',
          });

          const eventStartMessageLabel = intl.formatMessage({
            id: 'QuoteRequestForm.eventStartMessageLabel',
          });

          // Date Not Determined
          const notDeterminedTextMessage = intl.formatMessage({
            id: 'EditListingLocationForm.notDeterminedText',
          });
          const notDeterminedTextPlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.notDeterminedTextPlaceholder',
          });
          const notDeterminedTextPlaceholderMobile = intl.formatMessage({
            id: 'EditListingLocationForm.notDeterminedTextPlaceholderMobile',
          });
          const notDeterminedTextRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.notDeterminedTextRequired',
          });
          const notDeterminedTextRequired = validators.required(notDeterminedTextRequiredMessage);
          // change here

          const imageRequiredMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.imageRequired',
          });

          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          const unitType = config.bookingUnitType;
          const isNightly = unitType === LINE_ITEM_NIGHT;
          const isDaily = unitType === LINE_ITEM_DAY;

          const minTranslationKey = isNightly
            ? 'EditListingPricingForm.minPricePerNight'
            : isDaily
              ? 'EditListingPricingForm.minPricePerDay'
              : 'EditListingPricingForm.minPricePerUnit';

          const maxTranslationKey = isNightly
            ? 'EditListingPricingForm.maxPricePerNight'
            : isDaily
              ? 'EditListingPricingForm.maxPricePerDay'
              : 'EditListingPricingForm.maxPricePerUnit';

          const minPricePerUnitMessage = intl.formatMessage({
            id: minTranslationKey,
          });

          const minPricePlaceholderMessage = intl.formatMessage({
            id: 'EditListingPricingForm.minPriceInputPlaceholder',
          });

          const minPriceRequired = validators.required(
            intl.formatMessage({
              id: 'EditListingPricingForm.minPriceRequired',
            })
          );
          const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
          const miniPriceRequired = validators.moneySubUnitAmountAtLeast(
            intl.formatMessage(
              {
                id: 'EditListingPricingForm.minPriceTooLow',
              },
              {
                minPrice: formatMoney(intl, minPrice),
              }
            ),
            config.listingMinimumPriceSubUnits
          );
          const minPriceValidators = config.listingMinimumPriceSubUnits
            ? validators.composeValidators(minPriceRequired) //miniPriceRequired
            : minPriceRequired;


          const maxPricePerUnitMessage = intl.formatMessage({
            id: maxTranslationKey,
          });

          const maxPricePlaceholderMessage = intl.formatMessage({
            id: 'EditListingPricingForm.maxPriceInputPlaceholder',
          });

          const maxPriceRequired = validators.required(
            intl.formatMessage({
              id: 'EditListingPricingForm.maxPriceRequired',
            })
          );

          const requestMessage = intl.formatMessage({ id: 'EditListingPricingForm.request' });
          const requestPlaceholderMessage = intl.formatMessage({
            id: 'EditListingPricingForm.requestPlaceholder',
          });
          const requestRequiredMessage = intl.formatMessage({
            id: 'EditListingPricingForm.requestRequired',
          });

          const notAvailaleLabel = intl.formatMessage({
            id: 'EditListingPricingForm.notAvailaleLabel'
          });
          const deliveryRequiredLabel = intl.formatMessage({
            id: 'EditListingPricingForm.deliveryRequiredLabel'
          });
          const selectPlaceholder = intl.formatMessage({
            id: 'EditListingLocationForm.selectPlaceholder',
          });

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          let isRequirementRequest = false;
          for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
              const element = values[key];
              if (key.search('requirement-request') > -1 && element) {
                isRequirementRequest = true;
              }
            }
          }
          const notDeterminedPlaceholder = (typeof window !== 'undefined' && window.innerWidth < 768) ? notDeterminedTextPlaceholderMobile : notDeterminedTextPlaceholder;
          const submitDisabled = invalid || submitInProgress || !(exceptionCount || values.eventDuration == 'not-determined'); // || !isRequirementRequest || !images || !images.length;

          return (
            <Form className={classes} onSubmit={(e) => handleSubmit(e).then(() => {
              form.restart();
              this.setState({ submitted: true });
            })}>
              {/* <FormSpy onChange={onFormChange} /> */}
              <h3 className={css.Title}><FormattedMessage id={"QuoteRequestForm.heading"} /></h3>
              <div className={css.location}>
                <LocationAutocompleteInputField
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  name="location"
                  label={titleRequiredMessage}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.location}
                  validate={validators.composeValidators(
                    validators.autocompleteSearchRequired(addressRequiredMessage),
                    validators.autocompletePlaceSelected(addressNotRecognizedMessage)
                  )}
                />
              </div>

              <div className={css.name}>
                {(eventTypes.length || eventsOptions.length)
                  ? <FieldMultiSelect
                    className={css.field}
                    id={"eventType"}
                    name={"eventType"}
                    isMulti={false}
                    label={eventsLabel}
                    validate={eventsRequired}
                    placeholder={eventsPlaceholder}
                    options={eventTypes && eventTypes.length ? eventTypes.map(op => ({ ...op, value: op.name, label: op.name })) : eventsOptions}
                  />
                  : null}

                {eventSizesOptions && eventSizesOptions.length
                  ? <FieldMultiSelect
                    className={css.field}
                    isMulti={false}
                    id={"eventSize"}
                    name={"eventSize"}
                    label={eventSizeLabel}
                    autoComplete={"eventSize"}
                    validate={eventSizeRequired}
                    options={eventSizesOptions}
                  />
                  : null}

              </div>

              <div>
                <h3 className={css.signupSubHeading}>
                  <FormattedMessage id="EditListingLocationForm.eventDuration" />
                </h3>
                <div className={css.selectRadioButtons}>
                  <FieldRadioButton
                    className={css.radioBox}
                    id={'singleDay'}
                    name="eventDuration"
                    label={intl.formatMessage({ id: 'EditListingLocationForm.singleDay' })}
                    value="singleDay"
                    checkedClassName={css.checkedAvailable}
                  // showAsRequired={pristine}
                  />
                  <FieldRadioButton
                    className={css.radioBox}
                    id={'multipleDays'}
                    name="eventDuration"
                    label={intl.formatMessage({ id: 'EditListingLocationForm.multipleDays' })}
                    value="multipleDays"
                    checkedClassName={css.checkedNotAvailable}
                  // showAsRequired={pristine}
                  />
                  <FieldRadioButton
                    className={css.radioBox}
                    id={'not-determined'}
                    name="eventDuration"
                    label={intl.formatMessage({ id: 'EditListingLocationForm.notDetermined' })}
                    value="not-determined"
                    checkedClassName={css.checkedNotAvailable}
                  // showAsRequired={pristine}
                  />
                </div>
              </div>

              <div className={css.timeBox}>
                {(exceptionCount == 1 && 'singleDay' == values.eventDuration) || (exceptionCount && 'multipleDays' == values.eventDuration)
                  ? <section className={css.weeksSection}>
                    {fetchExceptionsInProgress ? (
                      <div className={css.exceptionsLoading}>
                        <IconSpinner />
                      </div>
                    ) : (
                      <div className={css.exceptions}>
                        <div className={css.exception}>
                          <div className={css.exceptionHeader}>
                            <table className={css.eventTable}>
                              <thead>
                                <tr>
                                  <th className={css.tableDate}>
                                    <FormattedMessage id="EditListingLocationForm.eventDate" />
                                  </th>
                                  <th className={css.tableHeading}>
                                    <FormattedMessage id="EditListingLocationForm.eventStartTime" />
                                  </th>
                                  <th className={css.tableHeading}>
                                    <FormattedMessage id="EditListingLocationForm.eventEndTime" />
                                  </th>
                                  <th className={css.tableHeading}>
                                    <FormattedMessage id="EditListingLocationForm.actionText" />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {sortedAvailabilityExceptions.map(availabilityException => {
                                  const { start, end, listingId } = availabilityException.attributes;
                                  return (
                                    <tr key={availabilityException.id.uuid}>
                                      <td className={css.tableDate}>
                                        {moment(start).tz(availabilityPlan.timezone).format("MMMM D, YYYY")}
                                      </td>
                                      <td className={css.tableData}>
                                        {moment(start).tz(availabilityPlan.timezone).format("h:mm a")}
                                      </td>
                                      <td className={css.tableData}>
                                        {moment(end).tz(availabilityPlan.timezone).format("h:mm a")}
                                      </td>
                                      <td className={css.tableData}>
                                        <button
                                          className={css.removeExceptionButton}
                                          onClick={() =>
                                            onDeleteAvailabilityException({ id: availabilityException.id, isLocal: listingId == null })
                                          }
                                        >
                                          <IconClose size="normal" className={css.removeIcon} />
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </section>
                  : ['singleDay', 'multipleDays'].includes(values.eventDuration)
                    ? null
                    : <FieldTextInput
                      id="notDeterminedText"
                      name="notDeterminedText"
                      className={css.title}
                      type="text"
                      label={notDeterminedTextMessage}
                      placeholder={notDeterminedPlaceholder}
                    // validate={notDeterminedTextRequired}
                    />}

                {values.eventDuration && ('multipleDays' == values.eventDuration || (exceptionCount == 0 && 'singleDay' == values.eventDuration))
                  ? <Button
                    className={css.addAnotherDate}
                    type={'button'}
                    onClick={() => setIsEditExceptionsModalOpen(values.eventDuration)}
                  >
                    <FormattedMessage id={exceptionCount == 0 ? "EditListingLocationForm.addDate" : "EditListingLocationForm.addAnotherDate"} />
                  </Button>
                  : null}
              </div>

              {/* Event Rentals */}
              {categories && categories.length
                ? <div className={css.accordanceMainBox}>
                  <h3 className={css.signupSubHeading}>
                    {["vendor", "venue"].includes(userType)
                      ? <FormattedMessage id={"EditListingLocationForm.userTypeServices"} values={{ userType: userType.charAt(0).toUpperCase() + userType.slice(1) }} />
                      : "Categories"}
                  </h3>
                  {categories.map((ca, ind) =>
                  (<div key={ca.key + '-' + ind} className={css.categoriesBox}>
                    {/* <h4>{ca.label}</h4> */}
                    <div className={css.categoriesCheckbox}>
                      {ca.subCategory && ca.subCategory.length
                        ? ca.subCategory.map((cat, index) => {
                          const minPrice = values[cat.label + "_minPrice"] || new Money(config.listingMinimumPriceSubUnits, config.currency);
                          const maxiPriceRequired = validators.moneySubUnitAmountAtLeast(
                            intl.formatMessage(
                              {
                                id: 'EditListingPricingForm.maxPriceTooLow',
                              },
                              {
                                minPrice: formatMoney(intl, minPrice),
                              }
                            ),
                            minPrice.amount
                          );
                          const maxPriceValidators = minPrice && minPrice.amount
                            ? validators.composeValidators(maxiPriceRequired)
                            : null;
                          const requirementRequest = Object.keys(values).filter(v => v.search([cat.label + "_requirement-request"]) > -1);
                          return (
                            <div key={cat.label + '-' + index} className={css.accordanceBox}>
                              <details className={css.accordion} style={{ backgroundColor: values[cat.label] ? '#f4e9df' : '' }}>
                                <summary className={css.accordionTitle}>
                                  {cat.label}
                                  <FieldCheckbox
                                    id={ind + '_' + index + "_checkbox"}
                                    name={ind + '_' + index + "_checkbox"}
                                    label={''}
                                    onChecked={(e) => {
                                      form.change(cat.label, e.target.checked);
                                      const filtered = ca.subCategory.filter(c => values[c.label]);
                                      form.change('nearByVendor', (filtered.length > 1 ? filtered.length : e.target.checked));
                                    }}
                                    className={css.priceCheck}
                                  />
                                </summary>
                                <div className={css.priceBoxWrap}>
                                  {values[cat.label + "_N/A"]
                                    ? null
                                    : <>
                                      <FieldCurrencyInput
                                        id={cat.label + "_minPrice"}
                                        name={cat.label + "_minPrice"}
                                        className={css.priceInput}
                                        label={minPricePerUnitMessage}
                                        placeholder={minPricePlaceholderMessage}
                                        currencyConfig={config.currencyConfig}
                                      // validate={minPriceValidators}
                                      />
                                      <FieldCurrencyInput
                                        id={cat.label + "_maxPrice"}
                                        name={cat.label + "_maxPrice"}
                                        className={css.priceInput}
                                        label={maxPricePerUnitMessage}
                                        placeholder={maxPricePlaceholderMessage}
                                        currencyConfig={config.currencyConfig}
                                        validate={maxPriceValidators}
                                      />
                                    </>}

                                  <FieldCheckbox
                                    id={cat.label + "_N/A"}
                                    name={cat.label + "_N/A"}
                                    label={notAvailaleLabel}
                                    className={css.priceCheck}
                                  />
                                </div>

                                <div className={css.requestMessage}>
                                  {requirementRequest.length
                                    ? requirementRequest.map((v, i) => <FieldTextInput
                                      key={v}
                                      id={v}
                                      name={v}
                                      className={css.title}
                                      type="text"
                                      label={i == 0 ? requestMessage : null}
                                      inputIcon={<span onClick={() => form.change((cat.label + "_requirement-request" + (parseInt(requirementRequest.length) + 1)), "")}><IconProfileSetup type="plus" /></span>}
                                      placeholder={requestPlaceholderMessage}
                                    />)
                                    : <FieldTextInput
                                      key={cat.label + "_requirement-request1"}
                                      id={cat.label + "_requirement-request1"}
                                      name={cat.label + "_requirement-request1"}
                                      className={css.title}
                                      type="text"
                                      label={requestMessage}
                                      inputIcon={<span onClick={() => form.change((cat.label + "_requirement-request" + 2), "")}><IconProfileSetup type="plus" /></span>}
                                      placeholder={requestPlaceholderMessage}
                                    />}
                                </div>
                                <div className={css.deliveryCheck}>
                                  <FieldCheckbox
                                    id={cat.label + "_isDelivery"}
                                    name={cat.label + "_isDelivery"}
                                    label={deliveryRequiredLabel}
                                  />
                                </div>
                              </details>
                              <hr className={css.dividerLine} />
                            </div>
                          );
                        })
                        : null}
                    </div>
                    {/* <hr className={css.dividerLine} /> */}
                  </div>)
                  )}
                </div>
                : null}

              <FieldTextInput
                className={css.optionalMessageRoot}
                type="textarea"
                id={'optionalMessage'}
                name="optionalMessage"
                label={optionalMessageLabel}
                placeholder={optionalMessagePlaceholder}
              // validate={optionalMessageRequired}
              />

              <div className={css.bottomWrapper}>
                <div className={css.nearVendorCheckbox}>
                  <FieldCheckbox
                    id={'nearByVendor'}
                    name="nearByVendor"
                    label={nearByVendorLabel}
                    className={css.singupcheck}
                  />
                </div>

                <div className={css.dividerLine} />

                {this.state.submitted
                  ? <p className={css.successMessage}><FormattedMessage id="EditListingLocationForm.yourProposalSent" /></p>
                  : null}

                {(isAuthenticated && !currentUserHasListings) ? <div className={css.createProfileMessage}>{intl.formatMessage({ id: "QuoteRequestForm.requestExecuteWhenListing" })}</div> : null}

                {this.state.submitted
                  ? <PrimaryButton
                    type="button"
                    onClick={() => window.location.reload()}
                    className={css.submitButton}
                  >
                    <FormattedMessage id="QuoteRequestForm.requestAQuote" />
                  </PrimaryButton>
                  : (isAuthenticated && currentUserHasListings) ?
                    <PrimaryButton
                      type={"submit"}
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      className={css.submitButton}
                    >
                      <FormattedMessage id="QuoteRequestForm.requestAQuote" />
                    </PrimaryButton>
                    :
                    (isAuthenticated && !currentUserHasListings) ?
                      <PrimaryButton
                        type={"submit"}
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        className={css.submitButton}
                        // onClick={() => {
                        //   if (typeof window != 'undefined') {
                        //     window.sessionStorage.setItem('quoteForm', JSON.stringify(values));
                        //     window.sessionStorage.setItem('path', window.location.pathname);
                        //     window.sessionStorage.setItem('eventCreation', listingId);
                        //   }
                        //   return history.push(createResourceLocatorString('ProfileSetupPage', routeConfiguration(), { step: 'basics' }, {}));
                        // }}
                      >
                        <FormattedMessage id="QuoteRequestForm.requestAQuote" />
                      </PrimaryButton>
                      :
                      <PrimaryButton
                        type={"submit"}
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        className={css.submitButton}
                        // onClick={() => {
                        //   if (typeof window != 'undefined') {
                        //     window.sessionStorage.setItem('quoteForm', JSON.stringify(values));
                        //     window.sessionStorage.setItem('path', window.location.pathname);
                        //   }
                        //   return history.push(createResourceLocatorString('SignupPage', routeConfiguration()));
                        // }}
                      >
                        <FormattedMessage id="QuoteRequestForm.requestAQuote" />
                      </PrimaryButton>}
              </div>
            </Form>
          );
        }}
      />
    )
  }
};

QuoteRequestFormComponent.defaultProps = { inProgress: false, eventsOptions: config.custom.eventType, eventSizesOptions: config.custom.eventSizes };

const { bool, func } = PropTypes;

QuoteRequestFormComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const QuoteRequestForm = compose(injectIntl)(QuoteRequestFormComponent);
QuoteRequestForm.displayName = 'QuoteRequestForm';

export default QuoteRequestForm;
