// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const getCategories = body => {
  return post('/api/getCategories', body);
};

// Algolia search data
export const createAlgoliaData = body => {
  return post('/api/createAlgoliaData', body);
};
export const updateAlgoliaData = body => {
  return post('/api/updateAlgoliaData', body);
};
export const deleteAlgoliaData = body => {
  return post('/api/deleteAlgoliaData', body);
};
export const searchAlgoliaData = body => {
  return post('/api/searchAlgoliaData', body);
};

export const createTransaction = (body) => {
  return post('/api/create-transaction', body);
};
export const getTransactions = (body) => {
  return post('/api/get-transactions', body);
};
export const updateTransaction = (body) => {
  return post('/api/update-transaction', body);
};
export const updateTransactions = (body) => {
  return post('/api/update-transactions', body);
};
export const sendProposalsForNewVendors = (body) => {
  return post('/api/sendProposals', body);
}
export const sendProposal = (body) => {
  return post('/api/sendProposal', body);
}
export const updateEventData = (body) => {
  return post('/api/updateEventData', body);
}
export const acornPurchased = (body) => {
  return post('/api/acornPurchased', body);
}
export const updatePublishedEvent = (body) => {
  return post('/api/updatePublishedEvent', body);
}
export const closeEvent = body => {
  return post('/api/closeEvent', body);
}

export const shareEvent = body => {
  return post('/api/shareEvent', body);
}

export const reportAnyVendor = body => {
  return post('/api/report', body);
}

export const createChannel = body => {
  return post('/api/channel/create', body);
}
export const updateChannel = body => {
  return post('/api/channel/update', body);
}

export const getChannels = body => {
  return post('/api/channels', body);
}

export const getMessages = body => {
  return post('/api/messages', body);
}

export const fetchTotalUnreadsCount = body => {
  return post('/api/totalunreadscount', body);
}

export const markAllQuotesAsRead = body => {
  return post('/api/markAllQuotesAsRead', body);
}

// Dots integration
export const createCustomer = body => {
  return post('/api/createCustomer', body);
}

export const updateCustomer = body => {
  return post('/api/updateCustomer', body);
}

export const createTransfers = body => {
  return post('/api/createTransfers', body);
}

export const createDotCustomer = body => {
  return post('/api/createCustomer', body);
}

export const getDotCustomer = body => {
  return post('/api/getCustomer', body);
}

export const verifyDotToken = body => {
  return post('/api/verifyToken', body);
}

export const paymentCustomer = body => {
  return post('/api/paymentCustomer', body);
}

export const getPaymentCustomer = body => {
  return post('/api/getPaymentCustomer', body);
}

export const createPaymentIntents = body => {
  return post('/api/createPaymentIntents', body);
}

export const confirmPaymentIntents = body => {
  return post('/api/confirmPaymentIntents', body);
}

export const sendPayout = body => {
  return post('/api/sendPayout', body);
}

export const sendVerificationToken = body => {
  return post('/api/sendVerificationToken', body);
}

export const verifyToken = body => {
  return post('/api/verifyToken', body);
}

export const getUsers = body => {
  return post('/api/getUsers', body);
}

export const getPaymentMethodApi = body => {
  return post('/api/getPaymentMethod', body);
}

export const attachPaymentMethods = body => {
  return post('/api/attachPaymentMethods', body);
}

export const detachPaymentMethods = body => {
  return post('/api/detachPaymentMethods', body);
}

export const listTransaction = body => {
  return post('/api/listTransaction', body);
}

export const getTransaction = body => {
  return post('/api/getTransaction', body);
}

export const payoutRequests = body => {
  return post('/api/payoutRequests', body);
}

export const createPayments = body => {
  return post('/api/createPayments', body);
}

export const changeFlows = body => {
  return post('/api/changeFlows', body);
}

export const sendMail = body => {
  return post('/api/sendMail', body);
}

// Invoice
export const deleteCloudinaryFiles = body => {
  return post('/api/deleteCloudinaryFile', body);
}
export const getInvoiceApi = body => {
  return post('/api/getInvoice', body);
}
export const createInvoiceApi = body => {
  return post('/api/createInvoice', body);
}
export const updateInvoiceApi = body => {
  return post('/api/updateInvoice', body);
}
export const createTaxesApi = body => {
  return post('/api/create-transaction', body);
}

export const eventCreationApi = body => {
  return post('/api/eventCreation', body);
}

// Trustap
export const createGuestUser = body =>{
  return post('/api/createTrustGuestUser', body);
};
export const createTrustFullUser = body =>{
  return post('/api/createTrustFullUser', body);
};
export const autheticateTrustFullUserCode = body =>{
  return post('/api/authenticateCode', body);
};
export const getTrustCharge = body =>{
  return post('/api/trustUserCharge', body);
};
export const createTransactionTrust = body =>{
  return post('/api/createTrustTransaction', body);
};
export const saveTransactionStatus = body =>{
  return post('/api/saveTransactionToDB', body);
};
export const fetchTrustProfileStatus = body =>{
  return post('/api/trustProfileStatus', body);
};
export const updateDataToDB = body =>{
  return post('/api/updateTrustDB', body);
};
export const fetchMongoDataByUserId = body =>{
  return post('/api/fetchMongoData', body);
};
export const fetchTrustapTransactions = body =>{
  return post('/api/fetchTrustTransactions', body);
};