import React from 'react';
import moment from 'moment';

import { getTotalQuoteAmount } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';

import {
    Button,
    IconProfileSetup,
    ResponsiveImage,
} from '../../components';

import css from './SendBirdChat.module.css';

const QuotesCard = props => {
    const {
        vendor,
        message,
        userType,
        PageName,
        statusEnum,
        vendorResponse,
        eventDetailData,
        messageSentByYou,
        setVendorResponseModal,
    } = props;

    const { categories, profileImageURL, businessName } = vendor.publicData || {};
    const { committedAmount } = getTotalQuoteAmount([eventDetailData]);
    const isExpired = eventDetailData.eventDuration && eventDetailData.eventDuration == "not-determined"
        ? false
        : eventDetailData.eventDates && eventDetailData.eventDates.length
            ? moment(eventDetailData.eventDates[0].start).isBefore(moment())
            : null;
    const isAvailable = eventDetailData.eventDuration && eventDetailData.eventDuration == "not-determined"
        ? true
        : eventDetailData.eventDates && eventDetailData.eventDates.length
            ? moment(eventDetailData.eventDates[0].start).isAfter(moment())
            : null;

    return (
        <div className={messageSentByYou ? css.rightMessage : css.leftMessage} key={'QuotesCard_' + message._id}>
            <div className={css.QuoteWrapper}>
                <div className={css.QuoteAvatarWrapper} style={{ flexDirection: messageSentByYou ? "row-reverse" : "row" }}>
                    <ResponsiveImage
                        className={css.QuoteAvatar}
                        alt='Event Image'
                        url={profileImageURL
                            ? profileImageURL
                            : vendorResponse && vendorResponse.uploadedFiles && vendorResponse.uploadedFiles.length
                                ? vendorResponse.uploadedFiles[0].secure_url
                                : ""}
                        variants={["scaled-large"]}
                    />
                    <div>
                        <p>{businessName} | {moment(vendorResponse.updatedAt * 1000).calendar()}</p>
                        <div><b>{businessName} </b>{eventDetailData.status == 'ACCEPTED'
                        ? 'proposal accepted'
                        : vendorResponse.quoteCheck == "requestMoreInfo"
                            ? "ask more info"
                            : "sent a proposal"}</div>
                    </div>
                </div>
                <div className={css.QuoCard}>
                    <div >
                        <div className={css.QuoCardHeader}>
                            <div className={css.category}>
                                {categories && categories.map((category) => <div key={'QuotesCard_' + message._id + '_' + category.label}>
                                    {category.label} {category.subCategory && '>'}&nbsp;
                                    {category.subCategory && category.subCategory.map((subCat, i) => (
                                        <span key={'QuotesCard_' + message._id + '_' + category.label + '_' + subCat.label}>
                                            {subCat.label}{category.subCategory.length > (i + 1) ? ', ' : ''}
                                        </span>))}
                                </div>)}
                                <b className={css.starIcon}><IconProfileSetup type={'star'} /> 5 Stars</b>
                            </div>
                            {/* {userType == 'planner'
                                ? <div className={css.favButton}>
                                    <IconCollection name="ICON_FAV" />
                                </div>
                                : null} */}
                        </div>
                        <div>
                            <div>
                                <h4><FormattedMessage id="EventDetailPage.requirements" /></h4>
                            </div>
                            <div className={css.chatCategories}>

                                {vendorResponse && vendorResponse.requirementQuotes
                                    ? eventDetailData?.categories.map(category =>
                                        category.subCategory.map(subCat => {
                                            const element = vendorResponse?.requirementQuotes && vendorResponse?.requirementQuotes[subCat.key];
                                            const isValidRequirements = !!(Object.hasOwn(subCat, 'requirements') && Object.keys(subCat.requirements).length);
                                            const requirements = isValidRequirements ? Object.keys(subCat.requirements).filter(requirement => requirement.includes('requirement-request')) : [];

                                            return requirements && requirements.length
                                                ? requirements.map(requirement => (element && element.requirements.includes(subCat.requirements[requirement]))
                                                    ? <div className={css.chatCategory} key={'QuotesCard_' + message._id + '_SubCategory_' + subCat.label + ' > ' + subCat.requirements[requirement]}>
                                                        {subCat.label + ' > ' + subCat.requirements[requirement]}
                                                        {/* + ' - ' + (element.quote / 100) + ' USD' */}
                                                    </div>
                                                    : null)
                                                : element && element.requirements.includes(subCat.label)
                                                    ? <div className={css.chatCategory} key={'QuotesCard_' + message._id + '_SubCategory_' + subCat.label}>
                                                        {subCat.label}
                                                    </div>
                                                    : null
                                        }))
                                    : eventDetailData?.subCategoriesLabel.map(subcat => (
                                        <div className={css.chatCategory} key={'QuotesCard_' + message._id + '_SubCategory_' + subcat}>
                                            {subcat}
                                        </div>))}
                            </div>
                        </div>
                        {vendorResponse && (committedAmount  || vendorResponse.quote)
                            ? <div>
                                <span className={css.textGrey}>
                                    <FormattedMessage id="EventDetailPage.quoteEstimate" />&nbsp;
                                </span>
                                <span className={css.totalsPrice}>
                                    ${(committedAmount / 100) || vendorResponse.quote} USD
                                </span>
                            </div>
                            : null}
                        {vendorResponse
                            ? <p className={css.note}>{vendorResponse.optionalNote}</p>
                            : null}
                        <Button
                            className={css.readQuoteBtn}
                            disabled={PageName == 'ProposalRequestsPage'
                                ? true
                                : ['REQUESTED'].includes(eventDetailData.status) //Need to ask Dareen and Kate 'INFO', 
                                    ? isExpired
                                    : true}
                            onClick={() => setVendorResponseModal()}
                        >
                            {PageName == 'ProposalRequestsPage'
                                ? statusEnum[eventDetailData.status]
                                : eventDetailData.status == 'PENDING' && isAvailable
                                    ? 'Pending'
                                    : ['INFO', 'ENQUIRY'].includes(eventDetailData.status) && isAvailable
                                        ? 'Message Awaiting'
                                        : eventDetailData.status == 'REQUESTED' && isAvailable
                                            ? 'Read Quote'
                                            : eventDetailData.status == 'ACCEPTED'
                                                ? 'Accepted'
                                                : eventDetailData.status == 'DECLINED'
                                                    ? 'Declined'
                                                    : 'Expired'}
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default QuotesCard;
