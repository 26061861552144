import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { createSlug } from '../../util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import {
    NamedLink,
    PrimaryButton,
    IconProfileSetup,
    ResponsiveImage,
} from '..';
import { SampleNextArrow, SamplePrevArrow } from '../../util/sagaHelpers';
import css from './SectionTrendingVendors.module.css';

const SectionTrendingVendors = props => {
    const {
        className,
        rootClassName,
        history,
        currentUser,
        categories,
        subCategories,
        trendingVendors,
        trendingVenues,
        isAuthenticated,
        onAddOrRemoveToConnected,
    } = props;

    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);

    const listings = trendingVendors && trendingVendors.length
        ? trendingVendors
        : trendingVenues && trendingVenues.length
            ? trendingVenues
            : [];
    // Calculate the length of listings to render
    const renderedListings = listings.filter(listing => ((listing.attributes.publicData.categoriesLabel && listing.attributes.publicData.categoriesLabel.length) || (listing.attributes.publicData.subCategoriesLabel && listing.attributes.publicData.subCategoriesLabel.length)));

    const settings = {
        speed: 800,
        slidesToShow: 4,
        arrows: true,
        infinite: renderedListings.length > 4 ? true : false,
        dots: false,
        centerMode: false,
        slidesToScroll: 1,
        draggable: false,
        cssEase: 'linear',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                    infinite: renderedListings.length > 3 ? true : false,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    infinite: renderedListings.length > 1 ? true : false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };

    return (renderedListings && renderedListings.length
        ? <div className={classes}>
            <div className={css.titleTop}>
                <h3>
                    <FormattedMessage id={trendingVenues && trendingVenues.length
                        ? "SectionTrendingVenues.title"
                        : "SectionTrendingVendors.title"} />
                </h3>
                {renderedListings.length > 4
                    ? <div>
                        <NamedLink
                            name={"AlgoliaSearchPage"}
                            className={css.seeAll}
                        >
                            <FormattedMessage id="SectionTrendingVendors.seeAll" />
                        </NamedLink>
                    </div>
                    : null}
            </div>
            <div className={css.tredingFoodBox}>
                <Slider {...settings} className={css.swiperSlide}>
                    {renderedListings
                        ? renderedListings.map((listing, i) => {
                            const { id, author, attributes, images } = listing;
                            const { publicData } = attributes || {};
                            const {
                                region,
                                city: hitCity,
                                rating = "5.0",
                                categories: hitCategories,
                                profileImageURL,
                                categoriesLabel,
                                subCategoriesLabel,
                                uploadedImagesUrls,
                            } = publicData || {},
                                isAddedPreferredVendor = currentUser && currentUser.id && currentUser.attributes.profile.publicData.addPreferredVendor && currentUser.attributes.profile.publicData.addPreferredVendor.filter(w => w.id == id.uuid).length;
                            const { displayName, publicData: uPublicData, profileImage } = (author && author.id && author.attributes.profile) || {};
                            const { profileImageURL: uProfileImageURL } = uPublicData || {};
                            const hasImages = uploadedImagesUrls && uploadedImagesUrls.length;
                            const categoriesImages = hasImages
                                ? []
                                : categoriesLabel && subCategoriesLabel
                                    ? [...categories.filter(c => categoriesLabel.includes(c.label)).map(c => c.image), ...subCategories.filter(sc => subCategoriesLabel.includes(sc.label)).map(sc => sc.image)]
                                    : [];
                            const params = uPublicData?.userListingId
                                ? {
                                    id: uPublicData.userListingId,
                                    city: listing && id && publicData.location && publicData.location.address
                                        ? createSlug(publicData.location.address.split(',')[0])
                                        : null,
                                    category: listing && id
                                        ? publicData.categoriesLabel && publicData.categoriesLabel.length
                                            ? createSlug(publicData.categoriesLabel[0])
                                            : publicData.categories && publicData.categories.length
                                                ? createSlug(publicData.categories[0].label)
                                                : ""
                                        : "",
                                    subcategory: listing && id
                                        ? publicData.subCategoriesLabel && publicData.subCategoriesLabel.length
                                            ? createSlug(publicData.subCategoriesLabel[0])
                                            : publicData.categories && publicData.categories[0] && publicData.categories[0].subCategory && publicData.categories[0].subCategory.length
                                                ? createSlug(publicData.categories[0].subCategory[0].label)
                                                : ""
                                        : "",
                                    slug: createSlug(displayName)
                                }
                                : {};

                            const firstImage = images && images.length ? images[0] : null;

                            return i < 8 && (categoriesLabel && subCategoriesLabel)
                                ? (
                                    <div key={listing.id.uuid + "_" + i} className={css.sliderCard} onClick={(e) => {
                                        e.preventDefault();
                                        history.push(createResourceLocatorString(
                                            (params && params.id && params.city && params.category && params.subcategory
                                                ? 'ListingCityPage'
                                                : 'ListingPage'),
                                            routeConfiguration(),
                                            params
                                        ));
                                    }}>
                                        <div className={css.trendCard}>
                                            <div className={css.trendTop}>
                                                <ResponsiveImage
                                                    url={uploadedImagesUrls && uploadedImagesUrls.length
                                                        ? uploadedImagesUrls[0].url
                                                        : (categoriesImages && categoriesImages.length && categoriesImages[0])}
                                                    image={firstImage}
                                                    variants={['landscape-crop', 'landscape-crop2x']}
                                                    alt={listing.attributes.title}
                                                />
                                                <span className={css.brandLogo}>
                                                    <ResponsiveImage
                                                        url={profileImageURL || uProfileImageURL}
                                                        className={css.logoBrand}
                                                        image={profileImage}
                                                        variants={['square-small', 'square-small2x']}
                                                        alt={listing.attributes.title}
                                                    />
                                                </span>
                                            </div>
                                            <div className={css.trendingBody}>
                                                <h5>{listing.attributes.title}</h5>
                                                <div className={css.categoriesText}>
                                                    <div className={css.categoriesTextWrapper}>
                                                        <p>
                                                            {hitCategories && hitCategories.length && hitCategories[0]
                                                                ? hitCategories[0].label
                                                                : null}
                                                            &nbsp; |  </p>
                                                        <span><IconProfileSetup type="star" />  {rating} Stars</span>
                                                    </div>
                                                    {hitCity && region
                                                        ? <div className={css.city}>{hitCity + ', ' + region}</div>
                                                        : null}
                                                </div>
                                                <div>

                                                    <p className={css.viewDetailButton}>
                                                        <FormattedMessage id="SectionTrendingVendors.viewDetails" /></p>

                                                    {currentUser && isAuthenticated
                                                        ? <PrimaryButton
                                                            className={css.addList}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                isAddedPreferredVendor
                                                                    ? onAddOrRemoveToConnected(listing.id.uuid, isAddedPreferredVendor)
                                                                    : onAddOrRemoveToConnected(listing.id.uuid, isAddedPreferredVendor, listing.attributes.title)
                                                            }}
                                                        >
                                                            {isAddedPreferredVendor
                                                                ? <>
                                                                    <IconProfileSetup type='preferred-list' />
                                                                    <FormattedMessage id="SectionTrendingVendors.addedPreferredList" />
                                                                </>
                                                                : <FormattedMessage id="SectionTrendingVendors.addPreferredList" />}
                                                        </PrimaryButton>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : null
                        })
                        : null}
                </Slider>
            </div>
        </div >
        : null
    );
};

const { node, string } = PropTypes;

SectionTrendingVendors.defaultProps = {
    className: null,
    rootClassName: null,
};

SectionTrendingVendors.propTypes = {
    className: string,
    rootClassName: string,
};

export default withRouter(SectionTrendingVendors);
