import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NotFoundPage } from './containers';

import CMSPage from './containers/CMSPage/CMSPage';
import PreviewResolverPage from './containers/PreviewResolverPage/PreviewResolverPage';
// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage'));
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage'));
const CityLandingPage = loadable(() => import(/* webpackChunkName: "CityLandingPage" */ './containers/CityLandingPage/CityLandingPage'));
const ListingPage = loadable(() => import(/* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'));
const ProfileSetupPage = loadable(() => import(/* webpackChunkName: "ProfileSetupPage" */ './containers/ProfileSetupPage/ProfileSetupPage'));
const SearchPage = loadable(() => import(/* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */  './containers/SearchPage/SearchPage'));
const AlgoliaSearchPage = loadable(() => import(/* webpackChunkName: "AlgoliaSearchPage" */ /* webpackPrefetch: true */  './containers/AlgoliaSearchPage/AlgoliaSearchPage'));
const AlgoliaSearchPageUpdated = loadable(() => import(/* webpackChunkName: "AlgoliaSearchPage" */ /* webpackPrefetch: true */  './containers/AlgoliaSearchPage/AlgoliaSearchPageUpdate'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage'));

const ChatPage = loadable(() => import(/* webpackChunkName: "ChatPage" */ './containers/ChatPage/ChatPage'));
const InvoiceDetailsPage = loadable(() => import(/* webpackChunkName: "InvoicePage" */ './containers/InvoiceDetailsPage/InvoiceDetailsPage'));
const EventDetailPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/EventDetailPage/EventDetailPage'));
const InvoicePage = loadable(() => import(/* webpackChunkName: "InvoicePage" */ './containers/InvoicePage/InvoicePage'));
const HelpCenterPage = loadable(() => import(/* webpackChunkName: "HelpCenterPage" */ './containers/HelpCenterPage/HelpCenterPage'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ './containers/ManageListingsPage/ManageListingsPage'));
const PaymentDashboardPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ './containers/PaymentDashboardPage/PaymentDashboardPage'));
const PreferredVendorsPage = loadable(() => import(/* webpackChunkName: "PreferredVendorsPage" */ './containers/PreferredVendorsPage/PreferredVendorsPage'));
const ProposalRequestsPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/ProposalRequestsPage/ProposalRequestsPage'));
const PurchaseAcornsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ './containers/PurchaseAcornsPage/PurchaseAcornsPage'));
const TrustapPage = loadable(() => import(/* webpackChunkName: "TrustapPage" */ './containers/TrustapPage/TrustapPage'));
// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ './containers/StyleguidePage/StyleguidePage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
  'PurchaseAcornsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;
const RedirectToUpdatedAlgoliaPage = (query) => {
  const { params, location } = query || {};
  const urlParams = params?.[0]?.split('/') ?? [];
  const actualParams = {
    region: urlParams?.[0] ?? location?.region ?? 'all',
    city: urlParams?.[1] ?? location?.city ?? 'all',
    categoriesLabel: urlParams?.[2] ?? location?.categoriesLabel ?? 'all',
    subCategoriesLabel: urlParams?.[3] ?? location?.subCategoriesLabel ?? 'all',
  };
  return <NamedRedirect name='AlgoliaSearchPageUpdate' params={{ ...actualParams }} />
};

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      loadData: params => pageDataLoadingAPI.SearchPage.loadData({ categoryName: 'LandingPage', ...params, pub_ListingType: 'VENDOR_PROFILE,VENUE_PROFILE' }),
    },
    {
      path: '/homepage/:categoryName',
      name: 'HomePage',
      component: LandingPage,
      loadData: params => pageDataLoadingAPI.SearchPage.loadData({ ...params, pub_ListingType: 'VENDOR_PROFILE,VENUE_PROFILE' }),
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/search',
      name: 'AlgoliaSearchPage',
      component: RedirectToUpdatedAlgoliaPage,
      // loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/search/:region/:city/:categoriesLabel/:subCategoriesLabel',
      name: 'AlgoliaSearchPageUpdate',
      component: AlgoliaSearchPageUpdated,
      loadData: pageDataLoadingAPI.AlgoliaSearchPage.loadData,
    },
    {
      path: '/search/*',
      name: 'SearchBasePage',
      component: RedirectToUpdatedAlgoliaPage,
      // loadData: params => pageDataLoadingAPI.SearchPage.loadData({ categoryName: 'CityLandingPage', ...params, pub_ListingType: 'VENDOR_PROFILE,VENUE_PROFILE' }),
    },
    // {
    //   path: '/search/:city',
    //   name: 'CityLandingPage',
    //   component: CityLandingPage,
    //   loadData: params => pageDataLoadingAPI.SearchPage.loadData({ categoryName: 'CityLandingPage', ...params, pub_ListingType: 'VENDOR_PROFILE,VENUE_PROFILE' }),
    // },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:city/:category/:subcategory/:slug/:id',
      name: 'ListingCityPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'basics' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      // extraProps: { allowOnlyOneListing: true },
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/preferred-vendors',
      name: 'PreferredVendorsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PreferredVendorsPage,
      loadData: pageDataLoadingAPI.PreferredVendorsPage.loadData,
    },
    {
      path: '/my-events',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      extraProps: { tab: 'upcoming' },
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/past-events',
      name: 'ManagePastListingsPage',
      auth: true,
      authPage: 'LoginPage',
      extraProps: { tab: 'past' },
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/proposal-requests',
      name: 'ProposalRequestsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProposalRequestsPage,
      loadData: pageDataLoadingAPI.ProposalRequestsPage.loadData,
    },
    {
      path: '/proposal/:slug/:txId/:tab',
      name: 'ProposalTabPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProposalRequestsPage,
      loadData: pageDataLoadingAPI.ProposalRequestsPage.loadData,
    },
    {
      path: '/event/:slug/:eventId/:tab',
      name: 'EventDetailPage',
      auth: true,
      authPage: 'LoginPage',
      component: EventDetailPage,
      loadData: (props) => pageDataLoadingAPI.ProposalRequestsPage.loadData({ ...props }),
    },
    {
      path: '/chat',
      name: 'ChatPage',
      component: ChatPage,
      auth: true,
      authPage: 'LoginPage',
      loadData: pageDataLoadingAPI.ChatPage.loadData,
    },
    {
      path: '/chat/:eventId',
      name: 'EventChatPage',
      component: ChatPage,
      auth: true,
      authPage: 'LoginPage',
      loadData: pageDataLoadingAPI.ChatPage.loadData,
    },
    {
      path: '/payment-dashboard',
      name: 'PaymentBaseDashboardPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="PaymentDashboardPage" params={{ tab: 'upcoming' }} />,
    },
    {
      path: '/payment-dashboard/:tab',
      name: 'PaymentDashboardPage',
      component: PaymentDashboardPage,
      auth: true,
      authPage: 'LoginPage',
      loadData: pageDataLoadingAPI.ProposalRequestsPage.loadData,
    },
    {
      path: '/invoice-details',
      name: 'InvoiceBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InvoiceDetailsPage" params={{ tab: 'invoice-history' }} />,
    },
    {
      path: '/invoice-details/:tab',
      name: 'InvoiceDetailsPage',
      component: InvoiceDetailsPage,
      auth: true,
      authPage: 'LoginPage',
      loadData: pageDataLoadingAPI.ProposalRequestsPage.loadData,
    },
    {
      path: '/invoice/:invoiceNumber',
      name: 'InvoicePage',
      component: InvoicePage,
      auth: true,
      authPage: 'LoginPage',
      loadData: pageDataLoadingAPI.InvoicePage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },
    {
      path: '/profile-setup',
      name: 'ProfileSetupBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ProfileSetupPage" params={{ step: 'basics' }} />,
    },
    {
      path: '/profile-setup/:step',
      name: 'ProfileSetupPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSetupPage,
    },
    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/trustap',
      name: "TrustapPage",
      auth: true,
      authPage: "LoginPage",
      component: TrustapPage,
      loadData: pageDataLoadingAPI.TrustapPage.loadData
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/purchase-acorns',
      name: 'PurchaseAcornsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PurchaseAcornsPage,
      loadData: pageDataLoadingAPI.PurchaseAcornsPage.loadData,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
      loadData: pageDataLoadingAPI.TermsOfServicePage.loadData,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
      loadData: pageDataLoadingAPI.PrivacyPolicyPage.loadData,
    },
    {
      path: '/help-center',
      name: 'HelpCenterPage',
      component: HelpCenterPage,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    {
      path: '/preview',
      name: 'PreviewResolverPage',
      component: PreviewResolverPage,
    },
    {
      path: '/p/:pageId',
      name: 'CMSPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
  ];
};

export default routeConfiguration;
