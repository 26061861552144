import { fetchCurrentUser } from "../../ducks/user.duck";
import { autheticateTrustFullUserCode, createTrustFullUser, fetchMongoDataByUserId, fetchTrustProfileStatus, fetchTrustapTransactions } from "../../util/api";
import qs from 'qs';


// ================ Action types ================ //
export const CONVERT_FULL_USER_REQUESTED = 'app/TrustapPage/CONVERT_FULL_USER_REQUESTED';
export const CONVERT_FULL_USER_SUCCESS = 'app/TrustapPage/CONVERT_FULL_USER_SUCCESS';
export const CONVERT_FULL_USER_ERROR = 'app/TrustapPage/CONVERT_FULL_USER_ERROR';

export const TRUST_AUTH_ERROR = 'app/TrustapPage/TRUST_AUTH_ERROR';
export const TRUST_AUTH_SUCCESS = 'app/TrustapPage/TRUST_AUTH_SUCCESS';

export const TRUST_PROFILE_COMPLETED_SUCCESS = 'app/TrustapPage/TRUST_PROFILE_COMPLETED_SUCCESS';
export const TRUST_PROFILE_COMPLETED_ERROR = 'app/TrustapPage/TRUST_PROFILE_COMPLETED_ERROR';

export const TRUST_TRANSACTION_REQUESTED = 'app/TrustapPage/TRUST_TRANSACTION_REQUESTED';
export const TRUST_TRANSACTION_SUCCESS = 'app/TrustapPage/TRUST_TRANSACTION_SUCCESS';
export const TRUST_TRANSACTION_ERROR = 'app/TrustapPage/TRUST_TRANSACTION_ERROR';

// ================ Reducer ================ //
const initialState = {
    trustapFullUserRequested: false,
    trustapFullUser: null,
    trustapFullUserError: null,
    trustAuthError: null,
    trustAuthenticated: false,
    isTrustProfileCompleted: false,
    isTrustProfileError: null,
    trustTransactionRequested: false,
    trustTransactions: null,
    trustTransactionError: null,
};

export default function TrustapPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;

    switch (type) {
        case CONVERT_FULL_USER_REQUESTED:
            return { ...state, trustapFullUserRequested: true };
        case CONVERT_FULL_USER_SUCCESS:
            return { ...state, trustapFullUser: payload, trustapFullUserRequested: false };
        case CONVERT_FULL_USER_ERROR:
            return { ...state, trustapFullUserRequested: false, trustapFullUserError: payload, trustapFullUser: null };
        case TRUST_AUTH_ERROR:
            return { ...state, trustAuthError: payload };
        case TRUST_AUTH_SUCCESS:
            return { ...state, trustAuthenticated: true };
        case TRUST_PROFILE_COMPLETED_SUCCESS:
            return { ...state, isTrustProfileCompleted: true };
        case TRUST_PROFILE_COMPLETED_ERROR:
            return { ...state, isTrustProfileCompleted: false, isTrustProfileError: payload };
        case TRUST_TRANSACTION_REQUESTED:
            return { ...state, trustTransactionRequested: true };
        case TRUST_TRANSACTION_SUCCESS:
            return { ...state, trustTransactionRequested: false, trustTransactions: payload };
        case TRUST_TRANSACTION_ERROR:
            return { ...state, trustTransactionRequested: false, trustTransactions: null, trustTransactionError: payload };
        default:
            return state;
    }
};

// ================ Action creators ================ //

const trustapFullUserRequested = () => ({
    type: CONVERT_FULL_USER_REQUESTED
});

const trustapFullUserSuccess = (response) => ({
    type: CONVERT_FULL_USER_SUCCESS,
    payload: response
});

const trustapFullUserError = (error) => ({
    type: CONVERT_FULL_USER_ERROR,
    payload: error
});

const trustAuthenticationFailed = (error) => ({
    type: TRUST_AUTH_ERROR,
    payload: error
});

const trustAuthenticationSuccess = () => ({
    type: TRUST_AUTH_SUCCESS,
});

const trustProfileCompletedSuccess = _ => ({
    type: TRUST_PROFILE_COMPLETED_SUCCESS,
});

const trustProfileCompletedError = data => ({
    type: TRUST_PROFILE_COMPLETED_SUCCESS,
    payload: data
});

const trustTransactionRequest = _ => ({
    type: TRUST_TRANSACTION_REQUESTED,
});

const trustTransactionSuccess = data => ({
    type: TRUST_TRANSACTION_SUCCESS,
    payload: data,
});

const trustTransactionError = error => ({
    type: TRUST_TRANSACTION_ERROR,
    payload: error
});

// ================ Thunks ================ //

export const handleTrustapRedirection = params => (dispatch, getState, sdk) => {
    dispatch(trustapFullUserRequested());

    const { trustGuestUserId } = params;

    createTrustFullUser({
        state: trustGuestUserId
    }).then(resp => {
        const { url } = resp;

        if (url && typeof window !== "undefined") {
            window.location.href = url;
        }
    }).catch(err => {
        dispatch(trustapFullUserError(err));
    });
};

export const loadData = () => async (dispatch, getState, sdk) => {
    const query = typeof window !== 'undefined' && qs.parse(window.location.search.slice(1));
    const { state, code } = query;
    return Promise.all([dispatch(fetchCurrentUser())]).then(async ([currentUser]) => {
        const { trustapGuestUser, trustFullUserId } = (currentUser && currentUser.id && currentUser.attributes.profile.publicData) || {};
        const { id: trustGuestUserId = null } = trustapGuestUser || {};

        // authenticate user code if any.
        if (trustGuestUserId !== null && trustGuestUserId == state) {
            await autheticateTrustFullUserCode({
                authenticationCode: code,
                currentUserId: currentUser.id.uuid
            }).then(_ => {
                return dispatch(trustAuthenticationSuccess());

            }).catch(error => {
                dispatch(trustAuthenticationFailed("auth-failed"));
                console.log(error, ">>>>> error-authenticate-token");
            })
        }

        // check if user profile completed.
        if (trustFullUserId) {
            await fetchTrustProfileStatus({
                fullUserId: trustFullUserId,
                isCallbackFn: false
            }).then(response => {
                const status = response.status.status;
                if (status == 'complete') {
                    dispatch(trustProfileCompletedSuccess());
                    const mongoPromise = fetchMongoDataByUserId({
                        isCallbackFn: false,
                        currentUserId: currentUser.id.uuid
                    });
                    Promise.all([mongoPromise]).then(async ([mongoData]) => {
                        dispatch(trustTransactionRequest());
                        const data = mongoData.data || [];
                        const transactionIds = Array.isArray(data) && data.length && data.map(resp => resp.trustTransactionId);
                        fetchTrustapTransactions({
                            isCallbackFn: false,
                            transactionIds: transactionIds,
                        }).then(response => {
                            const { message, data: trustTransactionData } = response || {};
                            dispatch(trustTransactionSuccess(trustTransactionData));
                            return trustTransactionData;
                        }).catch(error => {
                            dispatch(trustTransactionError(error));
                            return error;
                        });
                    });
                }
            }).catch(error => {
                dispatch(trustProfileCompletedError(error));
            });
        }
        return;
    });
};