import moment from 'moment';
import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import {
  changeFlows,
  createInvoiceApi,
  createTaxesApi,
  getTransactions,
  reportAnyVendor,
  sendMail,
  shareEvent,
  updateEventData,
  updateTransactions,
  // createChannel,
  // updateTransaction,
} from '../../util/api';
import { types as sdkTypes } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { showListing } from '../ListingPage/ListingPage.duck';
import { fetchTransaction } from '../TransactionPage/TransactionPage.duck';

const { UUID } = sdkTypes;
// ================ Action types ================ //

export const FETCH_EVENTS_REQUEST = 'app/ProposalRequestsPage/FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_SUCCESS = 'app/ProposalRequestsPage/FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_ERROR = 'app/ProposalRequestsPage/FETCH_EVENTS_ERROR';

export const FETCH_EVENT_DETAIL_REQUEST = 'app/ProposalRequestsPage/FETCH_EVENT_DETAIL_REQUEST';
export const FETCH_EVENT_DETAIL_SUCCESS = 'app/ProposalRequestsPage/FETCH_EVENT_DETAIL_SUCCESS';
export const FETCH_EVENT_DETAIL_ERROR = 'app/ProposalRequestsPage/FETCH_EVENT_DETAIL_ERROR';

export const UPDATE_EVENT_DETAIL_REQUEST = 'app/ProposalRequestsPage/UPDATE_EVENT_DETAIL_REQUEST';
export const UPDATE_EVENT_DETAIL_SUCCESS = 'app/ProposalRequestsPage/UPDATE_EVENT_DETAIL_SUCCESS';
export const UPDATE_EVENT_DETAIL_ERROR = 'app/ProposalRequestsPage/UPDATE_EVENT_DETAIL_ERROR';

export const SHARE_EVENTS_REQUEST = 'app/ProposalRequestsPage/SHARE_EVENTS_REQUEST';
export const REPORT_ANY_VENDOR = 'app/ProposalRequestsPage/REPORT_ANY_VENDOR';

// INVOICE
export const FETCH_INVOICE_REQUEST = 'app/ProposalRequestsPage/FETCH_INVOICE_REQUEST';
export const FETCH_INVOICE_SUCCESS = 'app/ProposalRequestsPage/FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICE_ERROR = 'app/ProposalRequestsPage/FETCH_INVOICE_ERROR';

export const CREATE_INVOICE_REQUEST = 'app/ProposalRequestsPage/CREATE_INVOICE_REQUEST';
export const CREATE_INVOICE_SUCCESS = 'app/ProposalRequestsPage/CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_ERROR = 'app/ProposalRequestsPage/CREATE_INVOICE_ERROR';

export const CREATE_TAXES_REQUEST = 'app/ProposalRequestsPage/CREATE_TAXES_REQUEST';
export const CREATE_TAXES_SUCCESS = 'app/ProposalRequestsPage/CREATE_TAXES_SUCCESS';
export const CREATE_TAXES_ERROR = 'app/ProposalRequestsPage/CREATE_TAXES_ERROR';

export const FETCH_TAXES_REQUEST = 'app/ProposalRequestsPage/FETCH_TAXES_REQUEST';
export const FETCH_TAXES_SUCCESS = 'app/ProposalRequestsPage/FETCH_TAXES_SUCCESS';
export const FETCH_TAXES_ERROR = 'app/ProposalRequestsPage/FETCH_TAXES_ERROR';

export const REPORT_USER_REQUEST = 'app/ProposalRequestsPage/REPORT_USER_REQUEST';
export const REPORT_USER_SUCCESS = 'app/ProposalRequestsPage/REPORT_USER_SUCCESS';
export const REPORT_USER_ERROR = 'app/ProposalRequestsPage/REPORT_USER_ERROR';

export const UPLOAD_FILE_REQUEST = 'app/ProposalRequestsPage/UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'app/ProposalRequestsPage/UPLOAD_FILE_SUCCESS';
export const CLEAR_UPLOAD_FILE_SUCCESS = 'app/ProposalRequestsPage/CLEAR_UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'app/ProposalRequestsPage/UPLOAD_FILE_ERROR';
export const REMOVE_UPLOADED_FILE = 'app/ProposalRequestsPage/REMOVE_UPLOADED_FILE';

// ================ Reducer ================ //

const initialState = {
  events: [],
  event: null,
  pagination: null,
  fetchEventInProgress: false,
  updateEventId: null,
  updateEventInProgress: false,
  fetchEventsInProgress: false,
  fetchEventError: false,
  fetchEventsError: false,
  shareEventInProgress: false,
  reportVendorInProgress: false,
  fetchInvoiceInProgress: false,
  invoices: [],
  lastInvoiceNumber: 35000,
  fetchInvoiceError: null,
  createInvoiceInProgress: false,
  createInvoiceError: false,
  createTaxesInProgress: false,
  createTaxesError: false,
  invoiceCreatedUrl: "",
  fetchTaxesInProgress: false,
  invoiceTaxes: [],
  fetchTaxesError: null,
  reportUserInProgress: false,
  reportUserError: null,
  files: [],
  removedFileIds: [],
};

export default function proposalRequestsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SHARE_EVENTS_REQUEST:
      return { ...state, shareEventInProgress: payload };
    case REPORT_ANY_VENDOR:
      return { ...state, reportVendorInProgress: payload };
    case FETCH_EVENTS_REQUEST:
      return { ...state, fetchEventsInProgress: true, events: [] };

    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: payload.data,
        pagination: payload.meta,
        fetchEventsInProgress: false,
        fetchEventsError: false
      };

    case FETCH_EVENTS_ERROR:
      return { ...state, fetchEventsInProgress: false, fetchEventsError: payload };

    case FETCH_EVENT_DETAIL_REQUEST:
      return {
        ...state,
        fetchEventInProgress: true,
        fetchEventError: false
      };
    case FETCH_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        event: payload.data,
        fetchEventInProgress: false,
      };

    case FETCH_EVENT_DETAIL_ERROR:
      return {
        ...state,
        fetchEventInProgress: false,
        fetchEventError: payload,
      };

    case UPDATE_EVENT_DETAIL_REQUEST:
      return {
        ...state,
        updateEventId: payload.id,
        updateEventInProgress: true,
        fetchEventError: false
      };
    case UPDATE_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        event: payload.data,
        updateEventId: null,
        updateEventInProgress: false,
      };
    case UPDATE_EVENT_DETAIL_ERROR:
      return {
        ...state,
        updateEventInProgress: false,
        fetchEventError: payload,
      };

    case FETCH_INVOICE_REQUEST:
      return { ...state, fetchInvoiceInProgress: true, invoices: [], fetchInvoiceError: null };
    case FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        fetchInvoiceInProgress: false,
        invoices: payload.invoices,
        lastInvoiceNumber: payload.lastInvoiceNumber
      };
    case FETCH_INVOICE_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, fetchInvoiceInProgress: false, fetchInvoiceError: payload };

    // invoice taxes
    case FETCH_TAXES_REQUEST:
      return { ...state, fetchTaxesInProgress: true, fetchTaxesError: null };
    case FETCH_TAXES_SUCCESS:
      return {
        ...state,
        fetchTaxesInProgress: false,
        invoiceTaxes: payload,
      };
    case FETCH_TAXES_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, fetchTaxesInProgress: false, fetchTaxesError: payload };

    // invoice taxes
    case REPORT_USER_REQUEST:
      return { ...state, reportUserInProgress: true, reportUserError: null };
    case REPORT_USER_SUCCESS:
      return {
        ...state,
        reportUserInProgress: false,
      };
    case REPORT_USER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, reportUserInProgress: false, reportUserError: payload };

    case CREATE_INVOICE_REQUEST:
      return { ...state, createInvoiceInProgress: true, createInvoiceError: null };
    case CREATE_INVOICE_SUCCESS:
      return { ...state, invoiceCreatedUrl: payload, createInvoiceInProgress: false, createInvoiceError: null };
    case CREATE_INVOICE_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, createInvoiceError: payload, createInvoiceInProgress: false };

    case CREATE_TAXES_REQUEST:
      return { ...state, createTaxesInProgress: true, createTaxesError: null };
    case CREATE_TAXES_SUCCESS:
      return { ...state, createTaxesInProgress: false, createTaxesError: null };
    case CREATE_TAXES_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, createTaxesError: payload, createTaxesInProgress: false };

    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        files: [...state.files, ...payload.params],
        uploadInProgress: true,
        uploadFileError: null,
      };
    case UPLOAD_FILE_SUCCESS: {
      return { ...state, uploadInProgress: false, };
    }
    case CLEAR_UPLOAD_FILE_SUCCESS: {
      return { ...state, files: [] };
    }
    case UPLOAD_FILE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, uploadFileError: true, uploadInProgress: false, };
    }
    case REMOVE_UPLOADED_FILE: {
      const { id } = payload;      
      // Only mark the file removed if it hasn't been added to the
      // listing already
      const files = state.files && state.files.length
        ? [...state.files.filter(file => file.id != id)]
        : [];      
      return { ...state, files };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchProposalRequest = () => ({
  type: FETCH_EVENTS_REQUEST
});

const fetchProposalSuccess = (response) => ({
  type: FETCH_EVENTS_SUCCESS,
  payload: response
});

const fetchProposalError = (error) => ({
  type: FETCH_EVENTS_ERROR,
  payload: error
});

export const fetchEventRequest = () => ({
  type: FETCH_EVENT_DETAIL_REQUEST,
});

export const fetchEventSuccess = response => ({
  type: FETCH_EVENT_DETAIL_SUCCESS,
  payload: response,
});

export const fetchEventError = e => ({
  type: FETCH_EVENT_DETAIL_ERROR,
  error: true,
  payload: e,
});

export const updateEventRequest = (id) => ({
  type: UPDATE_EVENT_DETAIL_REQUEST,
  payload: { id }
});

export const updateEventSuccess = response => ({
  type: UPDATE_EVENT_DETAIL_SUCCESS,
  payload: response,
});

export const updateEventError = e => ({
  type: UPDATE_EVENT_DETAIL_ERROR,
  error: true,
  payload: e,
});

export const shareEventRequest = payload => ({
  type: SHARE_EVENTS_REQUEST,
  payload
})

export const reportVendorInProgress = payload => ({
  type: REPORT_ANY_VENDOR,
  payload,
});

// Invoice
export const fetchInvoiceRequest = () => ({ type: FETCH_INVOICE_REQUEST });
export const fetchInvoiceSuccess = (payload, lastInvoiceNumber) => ({
  type: FETCH_INVOICE_SUCCESS,
  payload: { invoices: payload, lastInvoiceNumber }
});
export const fetchInvoiceError = e => ({
  type: FETCH_INVOICE_ERROR,
  error: true,
  payload: e,
});

// Taxes
export const fetchTaxesRequest = () => ({ type: FETCH_TAXES_REQUEST });
export const fetchTaxesSuccess = (payload) => ({
  type: FETCH_TAXES_SUCCESS,
  payload
});
export const fetchTaxesError = e => ({
  type: FETCH_TAXES_ERROR,
  error: true,
  payload: e,
});

// Report user
export const reportUserRequest = () => ({ type: REPORT_USER_REQUEST });
export const reportUserSuccess = (payload) => ({
  type: REPORT_USER_SUCCESS,
  payload
});
export const reportUserError = e => ({
  type: REPORT_USER_ERROR,
  error: true,
  payload: e,
});

export const createInvoiceRequest = () => ({ type: CREATE_INVOICE_REQUEST });
export const createInvoiceSuccess = (payload) => ({ type: CREATE_INVOICE_SUCCESS, payload });
export const createInvoiceError = e => ({
  type: CREATE_INVOICE_ERROR,
  error: true,
  payload: e,
});

export const createTaxesRequest = () => ({ type: CREATE_TAXES_REQUEST });
export const createTaxesSuccess = (payload) => ({ type: CREATE_TAXES_SUCCESS, payload });
export const createTaxesError = e => ({
  type: CREATE_INVOICE_ERROR,
  error: true,
  payload: e,
});

// SDK method: files.upload
export const initialClearUploadFile = result => ({
  type: CLEAR_UPLOAD_FILE_SUCCESS,
  payload: result?.data || null
});
export const uploadFileRequest = params => ({
  type: UPLOAD_FILE_REQUEST,
  payload: { params }
});
export const uploadFileSuccess = result => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: result || null
});
export const uploadFileError = error => ({
  type: UPLOAD_FILE_ERROR,
  payload: error, error: true
});

export const removeListingFile = payload => ({
  type: REMOVE_UPLOADED_FILE,
  payload,
});

// Files return id and file which we need to map with previously generated temporary id
export const requestFileUpload = (files) => (dispatch, getState, sdk) => {
  try {
    const stateData = Array(files.length).fill("_").map((_, i) => {
      const file = files[i];
      const id = `${file.name}_${Date.now()}`;
      const type = file.type;
      return { id, type, file };
    });
    
    dispatch(uploadFileRequest(stateData));
    dispatch(uploadFileSuccess(stateData));

    return stateData;
  } catch (error) {
    return dispatch(uploadFileError(error));
  }
}

export const shareEventWithVendors = (vendorIds, eventId) => (dispatch) => {
  dispatch(shareEventRequest(true));
  return shareEvent({ vendorIds, eventId })
    .then(() => dispatch(shareEventRequest(false)))
    .catch(() => dispatch(shareEventRequest(false)));
}

export const reportVendor = (params) => (dispatch) => {
  dispatch(reportVendorInProgress(true));
  return reportAnyVendor(params)
    .then(() => dispatch(reportVendorInProgress(false)))
    .catch(() => dispatch(reportVendorInProgress(false)))
}

// Events
export const fetchEvents = (query, needToUpdate = true, options, searchParams = {}) => (dispatch, getState) => {

  const { events } = getState().ProposalRequestsPage;
  if (needToUpdate || (events && events.length == 0)) {
    dispatch(fetchProposalRequest());
  }
  
  return getTransactions({ query, options })
    .then(response => {
      const eventData = JSON.parse(response.data);
      const { currentUser } = getState().user;
      dispatch(fetchProposalSuccess({
        data: eventData,
        meta: response.meta
      }));
      if (!needToUpdate) {
        dispatch(updateEventSuccess({ data: {}, meta: {} }));
      }
      if (eventData && eventData.length) {
        dispatch(getInvoices({ creatorId: currentUser.id.uuid, queryParams: searchParams }));
      }
      return eventData;
    })
    .catch(error => {
      dispatch(fetchProposalError(error));
    });
};

export const fetchEvent = query => (dispatch, getState) => {
  dispatch(fetchEventRequest());

  return getTransactions({ query })
    .then(response => {
      const { currentUser } = getState().user;
      const { userType } = (currentUser && currentUser.id && currentUser.attributes.profile.publicData) || {};
      const data = response.data ? JSON.parse(response.data)[0] : {};
      const { transactionId } = data;
      if (transactionId) {
        dispatch(fetchTransaction(new UUID(transactionId), userType == 'planner' ? 'customer' : 'provider'));
      }
      dispatch(fetchEventSuccess({ data, meta: response.meta }));
      return response;
    })
    .catch(error => {
      dispatch(fetchEventError(error));
    });
};

export const updateEvent = (payload, query) => (dispatch) => {
  const { _id, ...rest } = payload;
  dispatch(updateEventRequest(_id));

  if (_id) {
    return updateEventData({ id: _id, payload: rest })
      .then(response => {
        if (query && Object.keys(query).length) {
          dispatch(fetchEvents(query, true));
        } else {
          dispatch(updateEventSuccess({ data: payload, meta: response.meta }));
        }
        return response;
      })
      .catch(error => {
        dispatch(updateEventError(error));
      });
  }
  return updateTransactions({ eventId: rest.eventId, payload: rest })
    .then(response => {
      if (query && Object.keys(query).length) {
        dispatch(fetchEvents(query, true));
      } else {
        dispatch(updateEventSuccess({ data: payload, meta: response.meta }));
      }
      return response;
    })
    .catch(error => {
      dispatch(updateEventError(error));
    });
}

export const getInvoices = (payload) => (dispatch, getState, sdk) => {
  dispatch(fetchInvoiceRequest());

  const { creatorId, queryParams } = payload || {};
  const { searchT = "", tab, tabSort = false } = queryParams || {};

  const sortOptions = {}; // Define sort options based on tabSort
  switch (tabSort) {
    case 'Most Recent':
      sortOptions.createdAt = -1;
      break;
    case 'Ascending':
      sortOptions.invoiceNumber = 1;
      break;
    case 'Descending':
      sortOptions.invoiceNumber = -1;
      break;
    case 'Price: Low to high':
      sortOptions.invoiceTotal = 1;
      break;
    case 'Price: High to low':
      sortOptions.invoiceTotal = -1;
      break;
    default:
      sortOptions.createdAt = -1;
      break;
  }

  const searchMaybe = searchT && searchT.length
    ? {
      $or: [
        { plannerName: { $regex: searchT.toString().trim(), $options: 'i' } },
        { creatorName: { $regex: searchT.toString().trim(), $options: 'i' } },
        { creatorId: { $regex: searchT.toString().trim(), $options: 'i' } },
        { plannerId: { $regex: searchT.toString().trim(), $options: 'i' } },
      ]
    }
    : {};

  if (['invoice-history', 'history', 'create-invoice'].includes(tab)) {
    // No need to add over here for now
  } else if (tab == 'upcoming') {
    Object.assign(searchMaybe, {
      status: {
        $in: ['REQUESTED']
      }
    });
  } else if (tab == 'payout-settings') {
    Object.assign(searchMaybe, {
      status: {
        $in: ['PAID']
      }
    });
  } else {
    Object.assign(searchMaybe, {
      status: {
        $nin: ['REQUESTED']
      }
    });
  }

  getTransactions({
    tableName: "invoices",
    query: { $or: [{ creatorId }, { plannerId: creatorId }], ...searchMaybe },
    options: { sort: sortOptions }, // Pass sort options here
  })
    .then(res => {
      getTransactions({
        tableName: "invoices",
        query: {},
        options: {
          // sort: { invoiceNumber: 1, createdAt: -1 },
          sort: { createdAt: -1 },
          limit: 10
        }, // Pass sort options here
      }).then(resp => {
        const response = JSON.parse(resp.data) || [];
        const { invoiceNumber = '' } = response && response.length
          // ? response[response.length - 1]
          ? response[0]
          : {}
        const lastInvoiceNumber = invoiceNumber
          ? parseInt(invoiceNumber.split('#')[1])
          : 35000;
        return dispatch(fetchInvoiceSuccess(JSON.parse(res.data), lastInvoiceNumber));
      });
    })
    .catch(err => dispatch(fetchInvoiceError(err.error)));
};

export const reportAUser = (payload) => (dispatch, getState, sdk) => {
  dispatch(reportUserRequest());
  const { dynamic_template_data } = payload;

  return sendMail({
    dynamic_template_data,
    type: 'REPORT'
  })
    .then(() => {
      return dispatch(reportUserSuccess());
    })
    .catch(err => dispatch(reportUserError(err.error)));
};

export const getInvoiceTaxes = (payload) => (dispatch, getState, sdk) => {
  dispatch(fetchTaxesRequest());

  const { creatorId } = payload || {};
  const sortOptions = {};
  getTransactions({
    tableName: "taxes",
    query: { $or: [{ creatorId }] },
    sort: sortOptions, // Pass sort options here
  })
    .then(res => {
      return dispatch(fetchTaxesSuccess(JSON.parse(res.data)));
    })
    .catch(err => dispatch(fetchTaxesError(err.error)));
};

export const createInvoice = (payload) => (dispatch, getState, sdk) => {
  dispatch(createInvoiceRequest());

  return createInvoiceApi(payload)
    .then(res => {
      dispatch(createInvoiceSuccess(res));
      return res;
    })
    .catch(err => dispatch(createInvoiceError(err.error)));
};

export const createTaxes = (payload) => (dispatch, getState, sdk) => {
  dispatch(createTaxesRequest());

  return createTaxesApi({ tableName: "taxes", ...payload })
    .then(res => {
      dispatch(createTaxesSuccess(res));
      return res;
    })
    .catch(err => dispatch(createTaxesError(err.error)));
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const { txId, eventId, tab } = params;
  const validTab = ['quotes', 'request', 'messages'].includes(tab);
  const queryParams = new URLSearchParams(search);
  const searchParams = parse(search);
  const page = queryParams.get('page');
  const sort = queryParams.get('sort');

  const { events } = getState().ProposalRequestsPage;

  if (events && events.length == 0) {
    dispatch(fetchProposalRequest());
  }

  if (tab && ['upcoming', 'past'].includes(tab)) dispatch(fetchInvoiceRequest());

  let { currentUser } = getState().user || {};
  if (!currentUser || !(currentUser && currentUser.id)) {
    currentUser = await dispatch(fetchCurrentUser());
  }

  dispatch(getInvoiceTaxes({ creatorId: currentUser.id.uuid }));

  const query = {};
  const promisify = [];
  const options = {
    limit: 25,
    skip: page ? (Number(page) - 1) * 25 : 0,
    sort: {}
  }

  if (eventId) {
    promisify.push(dispatch(showListing(eventId)));
  }

  switch (sort) {
    case 'startDate':
      Object.assign(options.sort, { $or: [{ 'eventDates.start': { $gte: (moment().unix() * 1000) } }, { eventDuration: 'not-determined' }], 'eventDates.start': 1 })
      break;
    case 'lastDate':
      Object.assign(options.sort, { $or: [{ 'eventDates.start': { $gte: (moment().unix() * 1000) } }, { eventDuration: 'not-determined' }], 'eventDates.end': 1 })
      break;
    case 'newEvents':
      Object.assign(options.sort, { createdAt: -1 });
      Object.assign(query, { $or: [{ 'eventDates.start': { $gte: (moment().unix() * 1000) } }, { eventDuration: 'not-determined' }] });
      break;
    case 'pastEvents':
      Object.assign(query, { $or: [{ 'eventDates.start': { $lt: (moment().unix() * 1000) } }, { eventDuration: 'not-determined' }] });
      break;
    default:
      Object.assign(query, { $or: [{ 'eventDates.start': { $gte: (moment().unix() * 1000) } }, { eventDuration: 'not-determined' }] });
      break;
  }

  if (['REQUESTED', 'PENDING', 'ENQUIRY', 'INFO', 'ACCEPTED', 'EXPIRED'].includes(sort)) {
    Object.assign(query, { status: sort });
  } else {
    Object.assign(options.sort, { createdAt: -1 })
  }

  if (eventId) {
    Object.assign(query, {
      eventId,
      // status: { $nin: ['PENDING'] }
    });
  } else if (currentUser && currentUser.id && currentUser.attributes.profile.publicData.userListingId && currentUser.attributes.profile.publicData.userType != 'planner') {
    Object.assign(query, {
      vendorId: currentUser.attributes.profile.publicData.userListingId,
    });
  } else if (txId) {
    Object.assign(query, {
      status: { $in: ['PENDING', 'ENQUIRY', 'INFO'] }
    });
  }

  if (txId || tab) {
    promisify.push(dispatch(fetchEvent({ txId: txId || tab })));
  }

  promisify.push(dispatch(fetchEvents(query, validTab, options, { ...searchParams, tab })));

  return Promise.all(promisify);
}
